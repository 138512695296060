import { Drawer, Button, Typography } from "@mui/material";
import { Close } from "@mui/icons-material";
import PropTypes from 'prop-types';

RightDrawer.propTypes = {
    children: PropTypes.node.isRequired,
    open: PropTypes.bool.isRequired,
    setIsRightDrawerOpen: PropTypes.func.isRequired,
    otherProps: PropTypes.object,
};

export default function RightDrawer({ children, open, setIsRightDrawerOpen, ...otherProps }) {
    return (
        <Drawer
            variant="persistent"
            anchor="right"
            ModalProps={{
                BackdropProps: {
                    invisible: false,
                },
            }}
            PaperProps={{
                sx: {
                    width: window.innerWidth <= 768 ? "100vw" : '500px',
                    paddingTop: window.innerWidth <= 768 ? '60px' : '70px',
                    paddingBottom: '5px',
                    paddingLeft: '5px',
                    paddingRight: '5px',
                    backgroundColor: '#f2f4f6',
                    borderLeftWidth: '1px',
                    borderLeftColor: '#00242f',
                    borderLeftStyle: 'solid',
                },
            }}
            open={open}
            {...otherProps}
        >
            <div style={{ display: 'flex', flexDirection: 'column', height: '100%'}}>
                {children}
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', height: 'auto'}}>
                <Button
                    variant="contained"
                    color="error"
                    onClick={() => setIsRightDrawerOpen(false)}
                    style={{
                        height: window.innerWidth <= 768 ? 70 : 40
                    }}
                >
                    <Close />
                    <Typography>
                        Fechar
                    </Typography>
                </Button>
            </div>
        </Drawer>
    );
};