import { Slide, ThemeProvider } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import * as Sentry from "@sentry/browser";
import { SnackbarProvider } from "notistack";
import { Provider as ReduxProvider } from "react-redux";
import { createBrowserRouter, Outlet, RouterProvider } from "react-router-dom";
import GlobalStyles from "src/pages/layouts/GlobalStyles";
import routes from "src/routes";
import { requestInterceptor } from "src/services/http/interceptors";
import store from "src/store";
import { createTheme } from "src/theme";

import { SplashScreen, Toaster } from "./components";
import { AuthConsumer, AuthProvider } from "./context/auth-context";

import "react-perfect-scrollbar/dist/css/styles.css";
import { PaginationProvider } from "./context/pagination-context";
import { NotificationProvider } from "./context/notification-context";
import { FreshworksWidgetProvider } from "./context/freshworks-context";

requestInterceptor(store);

Sentry.init({
  dsn: "https://b81c7f0d253d437abe498506f96b46ad@glitchtip.crash.weesu.com.br/3",
});

const theme = createTheme();

const RootLayout = () => {
  return (
    <ReduxProvider store={store}>
      <LocalizationProvider dateAdapter={AdapterMoment}>
          <NotificationProvider>
            <AuthProvider>
              <AuthConsumer>
                {(auth) => {
                  const showSlashScreen = !auth.isInitialized;
                  return (
                    <ThemeProvider theme={theme}>
                      <SnackbarProvider
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "right",
                        }}
                        TransitionComponent={Slide}
                        maxSnack={5}
                      >
                        <GlobalStyles />
                        <FreshworksWidgetProvider>
                          <PaginationProvider>
                            {showSlashScreen ? <SplashScreen /> : <Outlet />}
                          </PaginationProvider>
                        </FreshworksWidgetProvider>
                        <Toaster />
                      </SnackbarProvider>
                    </ThemeProvider>
                );
              }}
            </AuthConsumer>
          </AuthProvider>
        </NotificationProvider>
      </LocalizationProvider>
    </ReduxProvider>
  );
};

const router = createBrowserRouter([
  {
    path: "/",
    element: <RootLayout />,
    children: routes,
  },
]);

const App = () => {
  return <RouterProvider router={router} />;
};

export default App;