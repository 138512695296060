import { lazy, Suspense } from "react";
import { Navigate, Outlet } from "react-router-dom";
import DashboardLayout from "src/pages/layouts/DashboardLayout";

const Login = lazy(() => import("src/pages/login/index"));
const Home = lazy(() => import("src/pages/home/index"));
const MapeamentoProdutos = lazy(() =>
  import("src/pages/estoque/produtos/mapeamento/index")
);
const AlocacaoProdutos = lazy(() =>
  import("src/pages/estoque/produtos/alocacao/index")
);
const SeparacaoProdutos = lazy(() =>
  import("src/pages/expedicao/separacao/index")
);
const SeparacaoCaixa = lazy(() =>
  import("src/pages/expedicao/separacao/caixa")
);
const SeparacaoOperacao = lazy(() =>
  import("src/pages/expedicao/separacao/separacao")
);
const Conferencia = lazy(() => import("src/pages/expedicao/conferencia"));
const ListagemProdutos = lazy(() =>
  import("src/pages/estoque/produtos/listagem/index")
);
const VisualizarProduto = lazy(() =>
  import("src/pages/estoque/produtos/visualizacao/index")
);
const TransfenciaProduto = lazy(() =>
  import("src/pages/estoque/produtos/transferencia/index")
);
const Transferindo = lazy(() => import("src/pages/estoque/produtos/transferir/realizar"));
const CaixaSeparacao = lazy(() =>
  import("src/pages/cadastros/caixa_de_separacao/index")
);
const Embalagens = lazy(() => import("src/pages/cadastros/embalagens/index"));
const EditEmbalagens = lazy(() =>
  import("src/pages/cadastros/embalagens/edit")
);
const MovmentacaoEstoque = lazy(() =>
  import("src/pages/estoque/produtos/movimentacao")
);
const Usuarios = lazy(() => import("src/pages/sistema/usuarios"));
const EditUsuarios = lazy(() => import("src/pages/sistema/usuarios/edit"));
const VisualizarLogs = lazy(() => import("src/pages/sistema/visualizar_logs"));
const RelacaoDeVolumes = lazy(() =>
  import("src/pages/expedicao/relacao_volumes")
);
const ExpedicoesRealizadas = lazy(() =>
  import("src/pages/expedicao/expedicoes_realizadas")
);
const PesquisaRapida = lazy(() =>
  import("src/pages/estoque/produtos/pesquisa_rapida")
);
const PrioridadeSeparacao = lazy(() =>
  import("src/pages/sistema/prioridade_separacao")
);
const SolicitaTransferencia = lazy(() =>
  import("src/pages/estoque/produtos/transferencia/solicita")
);
const InventarioLivre = lazy(() =>
  import("src/pages/estoque/produtos/inventario_livre")
);
const InventariarList = lazy(() =>
  import("src/pages/estoque/produtos/inventariar")
);
const InventarioLista = lazy(() =>
  import("src/pages/estoque/produtos/inventario")
);
const InventarioEdit = lazy(() =>
  import("src/pages/estoque/produtos/inventario/edit")
);
const Inventariando = lazy(() =>
  import("src/pages/estoque/produtos/inventariar/realizar")
);
const DivergenciaEstoque = lazy(() =>
  import("src/pages/relatorios/divergencia_estoque/index")
);
const EnderecoZerado = lazy(() =>
  import("src/pages/relatorios/enderecos_zerado/index")
);
const Enderecos = lazy(() => import("src/pages/cadastros/enderecos/index"));
const ProdutosCurvaA = lazy(() => import("./pages/relatorios/curva_a"));
const Transferir = lazy(() => import("src/pages/estoque/produtos/transferir"));
const Recebimentos = lazy(() => import("src/pages/estoque/produtos/recebimentos"));
const EditRecebimentos = lazy(() => import("src/pages/estoque/produtos/recebimentos/edit"));
const RecebimentosAgendamentos = lazy(() => import("src/pages/estoque/produtos/recebimentos/agendamentos"));
const SolicitacoesInventarioLista = lazy(() =>
  import("src/pages/estoque/produtos/solicitacoes_inventario")
);

const routes = [
  {
    path: "login",
    element: (
      <Suspense>
        <Login />
      </Suspense>
    ),
  },
  {
    path: "app",
    element: (
      <DashboardLayout>
        <Suspense>
          <Outlet />
        </Suspense>
      </DashboardLayout>
    ),
    children: [
      {
        index: true,
        element: <Navigate to="home" replace />,
      },
      {
        path: "home",
        element: (
          <Suspense>
            <Home />
          </Suspense>
        ),
      },
      {
        path: "cadastro",
        children: [
          {
            path: "caixa_separacao",
            element: (
              <Suspense>
                <CaixaSeparacao />
              </Suspense>
            ),
          },
          {
            path: "embalagens",
            element: (
              <Suspense>
                <Embalagens />
              </Suspense>
            ),
          },
          {
            path: "embalagens/editar/:id",
            element: (
              <Suspense>
                <EditEmbalagens />
              </Suspense>
            ),
          },
          {
            path: "enderecos",
            element: (
              <Suspense>
                <Enderecos />
              </Suspense>
            ),
          },
        ],
      },
      {
        path: "estoque",
        children: [
          {
            path: "pesquisa/rapida",
            element: (
              <Suspense>
                <PesquisaRapida />
              </Suspense>
            ),
          },
          {
            path: "mapeamento/produtos",
            element: (
              <Suspense>
                <MapeamentoProdutos />
              </Suspense>
            ),
          },
          {
            path: "alocacao/produtos",
            element: (
              <Suspense>
                <AlocacaoProdutos />
              </Suspense>
            ),
          },
          {
            path: "alocacao/produtos/:callback/:ean_produto",
            element: (
              <Suspense>
                <AlocacaoProdutos />
              </Suspense>
            ),
          },
          {
            path: "listagem/produtos",
            element: (
              <Suspense>
                <ListagemProdutos />
              </Suspense>
            ),
          },
          {
            path: "visualizar/produto/:id",
            element: (
              <Suspense>
                <VisualizarProduto />
              </Suspense>
            ),
          },
          {
            path: "movimentacao/produtos",
            element: (
              <Suspense>
                <MovmentacaoEstoque />
              </Suspense>
            ),
          },
          {
            path: "transferencia/produtos",
            element: (
              <Suspense>
                <TransfenciaProduto />
              </Suspense>
            ),
          },
          {
            path: "transferencia/produtos/:id",
            element: (
              <Suspense>
                <SolicitaTransferencia />
              </Suspense>
            ),
          },
          {
            path: "inventario/livre",
            element: (
              <Suspense>
                <InventarioLivre />
              </Suspense>
            ),
          },
          {
            path: "inventariar/lista",
            element: (
              <Suspense>
                <InventariarList />
              </Suspense>
            ),
          },
          {
            path: "inventariar/:inventory_id/endereco/:id",
            element: (
              <Suspense>
                <Inventariando />
              </Suspense>
            ),
          },
          {
            path: "transferir",
            element: (
              <Suspense>
                <Transferir />
              </Suspense>
            ),
          },
          {
            path: "transferir/:id",
            element: (
              <Suspense>
                <Transferindo />
              </Suspense>
            ),
          },
          {
            path: "inventario/lista",
            element: (
              <Suspense>
                <InventarioLista />
              </Suspense>
            ),
          },
          {
            path: "inventario/editar/:id",
            element: (
              <Suspense>
                <InventarioEdit />
              </Suspense>
            ),
          },
          {
            path: "recebimentos",
            element: (
              <Suspense>
                <Recebimentos />
              </Suspense>
            ),
          },
          {
            path: "recebimentos/editar/:id",
            element: (
              <Suspense>
                <EditRecebimentos />
              </Suspense>
            ),
          },
          {
            path: "recebimentos/agendamentos",
            element: (
              <Suspense>
                <RecebimentosAgendamentos />
              </Suspense>
            ),
          },
          {
            path: "inventario/solicitacoes",
            element: (
              <Suspense>
                <SolicitacoesInventarioLista />
              </Suspense>
            ),
          },
        ],
      },
      {
        path: "expedicao",
        children: [
          {
            path: "separacao",
            element: (
              <Suspense>
                <SeparacaoProdutos />
              </Suspense>
            ),
          },
          {
            path: "separacao_caixa/:id",
            element: (
              <Suspense>
                <SeparacaoCaixa />
              </Suspense>
            ),
          },
          {
            path: "separacao_operacao/:id",
            element: (
              <Suspense>
                <SeparacaoOperacao />
              </Suspense>
            ),
          },
          {
            path: "relacao_de_volumes",
            element: (
              <Suspense>
                <RelacaoDeVolumes />
              </Suspense>
            ),
          },
          {
            path: "expedicoes_realizadas",
            element: (
              <Suspense>
                <ExpedicoesRealizadas />
              </Suspense>
            ),
          },
          {
            path: "conferencia",
            element: (
              <Suspense>
                <Conferencia />
              </Suspense>
            ),
          },
        ],
      },
      {
        path: "relatorios",
        children: [
          {
            path: "divergencia_estoque",
            element: (
              <Suspense>
                <DivergenciaEstoque />
              </Suspense>
            ),
          },
          {
            path: "endereco_zerado",
            element: (
              <Suspense>
                <EnderecoZerado />
              </Suspense>
            ),
          },
          {
            path: "curva_a",
            element: (
              <Suspense>
                <ProdutosCurvaA />
              </Suspense>
            ),
          },
        ],
      },
      {
        path: "sistema",
        children: [
          {
            path: "usuarios",
            element: (
              <Suspense>
                <Usuarios />
              </Suspense>
            ),
          },
          {
            path: "usuarios/cadastro/:id",
            element: (
              <Suspense>
                <EditUsuarios />
              </Suspense>
            ),
          },
          {
            path: "visualizar/logs",
            element: (
              <Suspense>
                <VisualizarLogs />
              </Suspense>
            ),
          },
          {
            path: "prioridade-separacao",
            element: (
              <Suspense>
                <PrioridadeSeparacao />
              </Suspense>
            ),
          },
        ],
      },
      {
        path: "logout",
        element: <Navigate to="/" replace />,
      },
    ],
  },
  {
    path: "/",
    element: <Navigate to="/login" replace />,
  },
  {
    path: "*",
    element: <Navigate to="/login" replace />,
  },
];

export default routes;