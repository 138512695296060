import { ListItem, ListItemButton, ListItemText, Stack } from "@mui/material";
import { AnchorLink } from "../../styles";
import HelpCenterOutlinedIcon from '@mui/icons-material/HelpCenterOutlined';
import { neutral, primary } from "src/theme/colors";

function CentralDuvidasListItem() {
    const HELPER_BASE_URL = "https://ajuda.weesu.com.br";
    return (
        <AnchorLink href={HELPER_BASE_URL} target="_blank">
            <ListItem disablePadding>
              <ListItemButton
                sx={{
                  pt: 0,
                  pb: 0,
                  "&:hover": {
                    backgroundColor: primary["alpha8"],
                  },
                }}
              >
                <Stack alignItems="center" direction="row" flexWrap="wrap" gap={1}>
                  <HelpCenterOutlinedIcon sx={{color: neutral[900]}} />
                  <ListItemText primary="Central de dúvidas" />
                </Stack>
              </ListItemButton>
            </ListItem>
        </AnchorLink>
    )
}

export default CentralDuvidasListItem;