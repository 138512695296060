import { http } from "src/services";

// Action Types
export const Types = {
  USER_GROUPS_LOADING: "user/groups/loading",
  USER_GROUP_SET_DATA: "user/groups/set/data",
  USER_GROUPS_SET_DATA: "user/groups/list/set/data",
  USER_GROUPS_SET_PAGING: "user/groups/list/set/paging",
};

// Reducer
const initialState = {
  loading: false,
  groups: [],
  group: {},
  paginate: {
    page: 0,
    size: 25,
    count: 0,
  },
};

// Action Creators
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case Types.USER_GROUPS_LOADING:
      return { ...state, loading: action.payload };
    case Types.USER_GROUPS_SET_PAGING:
      return { ...state, paginate: { ...action.payload } };
    case Types.USER_GROUPS_SET_DATA:
      return { ...state, groups: action.payload };
    case Types.USER_GROUP_SET_DATA:
      return { ...state, group: action.payload };
    default:
      return state;
  }
}

export const Creators = {
  groupsLoading: (value) => ({ type: Types.USER_GROUPS_LOADING, payload: value }),
  groupsSetData: (users) => ({ type: Types.USER_GROUPS_SET_DATA, payload: users }),
  groupsSetPaginate: (paging) => ({ type: Types.USER_GROUPS_SET_PAGING, payload: paging }),
  groupSetData: (group) => ({ type: Types.USER_GROUP_SET_DATA, payload: group }),
  findAllGroups: (params) => {
    return (dispatch) => {
      http
        .get(`/v2/core/users/groups?${new URLSearchParams({ ...params })}`)
        .then(({ data: { rows, page, size, count } }) => {
          dispatch([
            Creators.groupsSetData(rows),
            Creators.groupsSetPaginate({ page, size, count }),
            Creators.groupsLoading(false),
          ]);
        })
        .catch((e) => {
          dispatch([Creators.userLoading(false)]);
        });
    };
  },
  findGroup: (id, callback) => {
    return (dispatch) => {
      http
        .get(`/v2/core/users/${parseInt(id)}`)
        .then(({ data }) => {
          dispatch([
            Creators.groupSetData(data),
            Creators.userLoading(false)
          ]);
          callback(data);
        })
        .catch((e) => {
          dispatch([Creators.userLoading(false)]);
        });
    };
  },
};
