import { http } from "src/services";

const AuthApi = {
  signIn: async (email, password, applicationId = "2") => {
    return new Promise((resolve, reject) => {
      http
        .post('/v2/core/auth/signin', { email, password, applicationId })
        .then((res) => resolve(res.data))
        .catch((err) => reject(err));
    });
  },
  signUp: async (customer, user, applicationId = "2") => {
    return new Promise((resolve, reject) => {
      http
        .post('/v2/core/auth/signup', { customer, user, applicationId })
        .then((res) => resolve(res.data))
        .catch((err) => reject(err));
    });
  },  
  recoveryPassword: async (email) => {
    return new Promise((resolve, reject) => {
      http
        .post('/v2/core/auth/recoverypassword', { email })
        .then((res) => resolve(res.data))
        .catch((err) => reject(err));
    });
  },  
  resetPassword: async (token, password) => {
    return new Promise((resolve, reject) => {
      http
        .post('/v2/core/auth/resetpassword', { token, password })
        .then((res) => resolve(res.data))
        .catch((err) => reject(err));
    });
  },  
  getAuthenticated: async (accessToken) => {
    return new Promise((resolve, reject) => {
      http
        .get('/v2/core/auth/authenticated', {
          headers: { Authorization: `Bearer ${accessToken}` },
        })
        .then((res) => resolve(res.data))
        .catch((err) => reject(err));
    });
  },
  
};

export default AuthApi;