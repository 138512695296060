import { Box, IconButton, Stack, useTheme } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { useEffect, useState } from "react";
import { useNotification } from "src/context/notification-context";
import { getMobileOperatingSystem } from "src/utils/getUtils";

export const TopNotificationAlert = ({ notification }) => {
  const { hideNotification } = useNotification();
  const theme = useTheme();
  const [styles, setStyles] = useState({
    backgroundColor: theme.palette.communicate.default,    
    label: 'Comunicado'
  });

  // Define os estilos com base no tipo da notificação
  const getStylesByType = (type) => {
    switch (type) {
      case 'Info':
        return { backgroundColor: theme.palette.communicate.info, label: 'Informação' };
      case 'Warning':
        return { backgroundColor: theme.palette.communicate.warning, label: 'Atenção' };
      case 'Critical':
        return { backgroundColor: theme.palette.communicate.critical, label: 'Crítico' };
      default:
        return { backgroundColor: theme.palette.communicate.default, label: 'Comunicado' };
    }
  };

  useEffect(() => {
    const styles = getStylesByType(notification.type || "default");
    setStyles(styles);
  }, [notification.type]);

  // Se a notificação estiver oculta, não renderiza nada
  if (notification.hidden) return null;

  return (
    <Box
      zIndex={theme.zIndex.appBar}
      elevation={0}
      style={{ backgroundColor: styles.backgroundColor }}
    >
      <Stack direction="row" sx={{ alignItems: 'center', justifyContent: 'space-between' }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            alignItems: 'center',
            justifyContent: 'center',
            paddingLeft: '1rem',
            paddingTop: '1rem',
            paddingBottom: '1rem',
            minHeight: getMobileOperatingSystem() === "unknown" ? 40 : 145,
            maxHeight: getMobileOperatingSystem() === "unknown" ? 40 : 145
          }}
        >
          <span style={{ color: 'white', display: 'flex', flexDirection: 'row', width: '100%' }}>
            {notification.message}
            {notification.redirect_link && notification.redirect_link !== '' && (
              <>
                : <a href={notification.redirect_link} style={{ color: "#000", marginLeft: '0.5rem' }} target="_blank" rel="noopener noreferrer">
                  Clique aqui para saber mais detalhes...
                </a>
              </>
            )}
          </span>
        </Box>
        <IconButton edge="end" color="inherit" onClick={() => { hideNotification(notification.id); }} sx={{ marginRight: '1rem' }}>
          <CloseIcon className="shrink-0" />
        </IconButton>
      </Stack>
    </Box>
  );
};
