/* eslint-disable eqeqeq */
import {
  Chip,
  IconButton,
  InputAdornment,
  ListItemIcon,
  Menu,
  MenuItem,
  Paper,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import useStyles from "./styles";

import {
  ClearAll,
  DynamicFeed as Em,
  DragHandle as Igual,
  YoutubeSearchedFor as Indefinido,
  ArrowRight as MaiorIgual,
  ChevronRight as MaiorQue,
  ArrowLeft as MenorIgual,
  ChevronLeft as MenorQue,
  Refresh,
} from "@mui/icons-material";
import _ from "lodash";
import { Search } from "react-feather";
import {
  getMobileOperatingSystem,
  getRequestOperator,
} from "src/utils/getUtils";

/**
 * React Component UnicFilterBar, recebe alguns parametros e cria um componente baseado em um input de text que cria alguns
 * chip com objetos em um array para criar um filtro dinamico.
 * @param {String} placeholder - é o texto que aparece de fundo dentro do input.
 * @param {Object} defaultFilter - Objeto que representa o filtro default que será adicionado quando nao escolher um filtro e pressionar enter.
 * @param {Array(Object)} filterOptions - Array de Objetos que representam os filtros disponiveis.
 * @param {Array(Object)} filtersValues - Array de Objetos que representam os filtros adicionados.
 * @param {function} onFiltersValues - Função que retorno o filtro à adicionado
 * @returns
 */
function UnicFilterBar({
  placeholder,
  defaultFilter = undefined,
  filterOptions = [],
  filtersValues = [],
  onFiltersValues,
  onRefresh,
  endbuttons,
  startbuttons,
}) {
  const inputRef = useRef(null);
  const classes = useStyles();
  const [anchorMenu, setAnchorMenu] = useState(null);
  const [filtro, setFiltro] = useState("");

  function onKeyDownPress(e) {
    if (e.key === "\\") {
      setAnchorMenu(e.currentTarget);
      inputRef.current.focus();
    } else if (e.key === "Escape") {
      if (filtro === "") {
        onFiltersValues(() => []);
      } else {
        setFiltro("");
      }
    } else if (e.key === "Enter") {
      const filtros = filtro.split("//");
      if (filtros.length > 1) {
        let values = _.remove(filtersValues, function ([label, expression]) {
          return label + expression != filtros[0] + filtros[1];
        });
        const objectFilter = filterOptions.filter(
          (item) => item.display_name === filtros[0]
        );
        onFiltersValues([...values, [...filtros, objectFilter[0].id]]);
        setFiltro(`${filtros[0]}//${filtros[1]}//`);
      } else if (defaultFilter !== undefined && filtro.length > 0) {
        onSearch();
      } else {
        onFiltersValues(() => []);
      }
      inputRef.current.focus();
    }
  }

  function onClickMenu({ display_name }, expression) {
    setFiltro(`${display_name}//${getRequestOperator(expression)}//`);
    setAnchorMenu(null);
  }

  function onClickClear() {
    setAnchorMenu(null);
    setFiltro("");
    onFiltersValues([]);
  }

  function getIcon(chip) {
    return chip === "eq" ? (
      <Igual />
    ) : chip === "lt" ? (
      <MenorQue />
    ) : chip === "lte" ? (
      <MenorIgual />
    ) : chip === "gt" ? (
      <MaiorQue />
    ) : chip === "gte" ? (
      <MaiorIgual />
    ) : chip === "in" ? (
      <Em />
    ) : (
      <Indefinido />
    );
  }

  const handleClose = () => {
    setAnchorMenu(null);
  };

  const onSearch = () => {
    if (defaultFilter !== undefined && filtro.length > 0) {
      let values = _.remove(filtersValues, function ([label, expression]) {
        return (
          label + expression !=
          defaultFilter.display_name +
            getRequestOperator(defaultFilter.operators[0], 1)
        );
      });

      onFiltersValues([
        ...values,
        [
          defaultFilter.display_name,
          getRequestOperator(defaultFilter.operators[0], 1),
          filtro,
          defaultFilter.id,
        ],
      ]);
    }
    setFiltro("");
  };

  useEffect(() => {
    onClickClear();
  }, []);
  
  return (
    <>
      <Menu
        id="filters_menu"
        anchorEl={anchorMenu}
        keepMounted
        open={Boolean(anchorMenu)}
        onClose={handleClose}
      >
        {filterOptions?.map((item) => {
          return item.operators?.map((sitem) => (
            <MenuItem onClick={() => onClickMenu(item, sitem)}>
              <ListItemIcon>{getIcon(sitem)}</ListItemIcon>
              <Typography variant="inherit">{item.display_name}</Typography>
            </MenuItem>
          ));
        })}
        <MenuItem onClick={() => onClickClear()}>
          <Typography variant="inherit">Limpar filtros</Typography>
        </MenuItem>
      </Menu>
      <Paper className={classes.root}>
        {startbuttons}
        <TextField
          inputRef={inputRef}
          aria-controls="filters_menu"
          placeholder={`${
            placeholder ? `${placeholder} ou ` : "digite"
          } "\\" para utilizar um smart filter`}
          className={classes.inputFilter}
          label="Digite seu filtro aqui"
          InputLabelProps={{ shrink: true }}
          margin="dense"
          variant="outlined"
          onKeyDown={onKeyDownPress}
          value={filtro}
          onChange={(e) => setFiltro(e.target.value)}
          InputProps={{
            startAdornment: getMobileOperatingSystem() !== "unknown" && (
              <InputAdornment position="start">
                <IconButton
                  aria-label="Limpar filtros"
                  onClick={(e) => setAnchorMenu(e.currentTarget)}
                >
                  <ClearAll />
                </IconButton>
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="Filtrar"
                  onClick={() => {
                    const filtros = filtro.split("//");
                    if (filtros.length > 1) {
                      let values = _.remove(
                        filtersValues,
                        function ([label, expression]) {
                          return label + expression != filtros[0] + filtros[1];
                        }
                      );
                      const objectFilter = filterOptions.filter(
                        (item) => item.display_name === filtros[0]
                      );
                      onFiltersValues([
                        ...values,
                        [...filtros, objectFilter[0].id],
                      ]);
                    } else if (
                      defaultFilter !== undefined &&
                      filtro.length > 0
                    ) {
                      onSearch();
                    } else {
                      onFiltersValues(() => []);
                    }
                    setFiltro("");
                  }}
                >
                  <Search />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        {endbuttons}
      </Paper>
      {filtersValues?.length > 0 && (
        <Paper
          component="ul"
          className={classes.rootFilter}
          sx={{
            marginBottom: "6px",
            alignItems: "center",
          }}
        >
          {filtersValues?.map(([_label, _expression, _value], index) => {
            return (
              <li key={index} style={{ marginLeft: "2px", marginRight: "2px" }}>
                <Chip
                  sx={{ backgroundColor: "rgba(179, 223, 223, 0.5)" }}
                  label={
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <Typography
                        variant="subtitle1"
                        sx={{
                          marginTop: "auto",
                          marginBottom: "auto",
                          fontSize: 14,
                        }}
                      >
                        {_label}
                      </Typography>
                      &nbsp;
                      <span style={{ color: "#696969" }}>
                        {getIcon(getRequestOperator(_expression, 0))}
                      </span>
                      <Typography
                        variant="subtitle2"
                        sx={{
                          marginTop: "auto",
                          marginBottom: "auto",
                          marginLeft: "5px",
                          fontSize: 15,
                        }}
                      >
                        {_value}
                      </Typography>
                    </div>
                  }
                  onDelete={() => {
                    let values = _.remove(
                      filtersValues,
                      function ([label, expression, value]) {
                        return (
                          label + expression + value !=
                          _label + _expression + _value
                        );
                      }
                    );
                    onFiltersValues(values);
                  }}
                  className={classes.chip}
                />
              </li>
            );
          })}
          {onRefresh && (
            <Tooltip title="Reaplicar os filtros">
              <IconButton
                color="primary"
                aria-label="refresh"
                component="span"
                sx={{ marginLeft: "auto" }}
                onClick={onRefresh}
              >
                <Refresh />
              </IconButton>
            </Tooltip>
          )}
        </Paper>
      )}
    </>
  );
}

export default UnicFilterBar;
