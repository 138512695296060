import jwt_decode from "jwt-decode";
import _ from "lodash";
import PropTypes from "prop-types";
import { createContext, useCallback, useEffect, useReducer } from "react";
import { modules } from "src/modules";

import authApi from "../api/auth";
import userApi from "../api/user";
import useInterval from "../hooks/use-interval";
import { useLocation, useNavigate } from "react-router-dom";
import { Creators as UserCreators } from "src/store/ducks/user";
import { useNotification } from "./notification-context";

const STORAGE_KEY = "accessToken";

var ActionType;
(function (ActionType) {
  ActionType["INITIALIZE"] = "INITIALIZE";
  ActionType["SIGN_IN"] = "SIGN_IN";
  ActionType["SIGN_UP"] = "SIGN_UP";
  ActionType["SIGN_OUT"] = "SIGN_OUT";
  ActionType["PERMISSIONS"] = "PERMISSIONS";
  ActionType["PARAMETERS"] = "PARAMETERS";
})(ActionType || (ActionType = {}));

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
  permissions: null,
  parameters: null,
};

const handlers = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated, user, permissions, parameters } = action.payload;

    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
      permissions,
      parameters,
    };
  },
  SIGN_IN: (state, action) => {
    const { user, permissions, parameters } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
      permissions,
      parameters,
    };
  },
  SIGN_UP: (state, action) => {
    const { user, permissions, parameters } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
      permissions,
      parameters,
    };
  },
  SIGN_OUT: (state) => ({
    ...state,
    isAuthenticated: false,
    user: null,
    permissions: null,
    parameters: null,
  }),
  PERMISSIONS: (state, action) => {
    return {
      ...state,
      permissions: action.payload,
    };
  },
  PARAMETERS: (state, action) => {
    return {
      ...state,
      parameters: action.payload,
    };
  },
};

const reducer = (state, action) =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

export const AuthContext = createContext({
  ...initialState,
  signIn: () => Promise.resolve(),
  signUp: () => Promise.resolve(),
  signOut: () => Promise.resolve(),
});

export const AuthProvider = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialState);
  const { setNotifications } = useNotification();
  const initialize = useCallback(async () => {
    try {
      const accessToken = window.localStorage.getItem(STORAGE_KEY);
      if (accessToken && !['/login', '/'].includes(window.location.pathname)) {
        const { user, token } = await authApi.getAuthenticated(accessToken);
        if (!user) {
          window.localStorage.removeItem(STORAGE_KEY);
          dispatch({
            type: ActionType.INITIALIZE,
            payload: {
              isAuthenticated: false,
              user: null,
              permissions: null,
              parameters: null,
            },
          });
          navigate("/", { replace: true });
        } else {
          dispatch({
            type: ActionType.INITIALIZE,
            payload: {
              isAuthenticated: true,
              user,
            },
          });
          window.localStorage.setItem(STORAGE_KEY, token);
          const response = await UserCreators.getCachedUserData();
          if(response && response.communicates) {
            setNotifications(
              response.communicates.map((n, index) => ({
                ...n,
                id: index,
                hidden: n.hidden ?? false
              }))
            );
          }
        }
      } else {
        dispatch({
          type: ActionType.INITIALIZE,
          payload: {
            isAuthenticated: false,
            user: null,
            permissions: null,
            parameters: null,
          },
        });
      }
    } catch (err) {
      dispatch({
        type: ActionType.INITIALIZE,
        payload: {
          isAuthenticated: false,
          user: null,
          permissions: null,
          parameters: null,
        },
      });
    }
  }, [dispatch]);

  const refreshToken = async () => {
    const accessToken = window.localStorage.getItem(STORAGE_KEY);
      if (accessToken) {
        const { token } = await authApi.getAuthenticated(accessToken);
        if (token) {
          window.localStorage.setItem(STORAGE_KEY, token);
        }
      }    
  };

  useEffect(() => {
    initialize();
  }, []);

  const signIn = useCallback(
    async (email, password) => {
      const { user, token, userCheckedLastVersion, status, communicates } = await authApi.signIn(email, password);
      window.localStorage.setItem("user_last_checked_version", userCheckedLastVersion === true ? "true" : "false");

      if (_.isEmpty(user)) {
        throw new Error("Usuário ou senha inválidos");
      }

      window.localStorage.setItem(STORAGE_KEY, token);
      dispatch({
        type: ActionType.SIGN_IN,
        payload: {
          user,
        },
      });

      if(communicates && communicates.length > 0) {
        setNotifications(
          communicates.map((n, index) => ({
            ...n,
            id: index,
            hidden: n.hidden ?? false
          }))
        );
      }
    },
    [dispatch]
  );

  const signUp = useCallback(
    async (payload) => {
      const { user, token, communicates } = await authApi.signUp({
        ...payload,
      });
      window.localStorage.setItem(STORAGE_KEY, token);
      dispatch({
        type: ActionType.SIGN_UP,
        payload: {
          user,
        },
      });
      if(communicates && communicates.length > 0) {
        setNotifications(
          communicates.map((n, index) => ({
            ...n,
            id: index,
            hidden: n.hidden ?? false
          }))
        );
      }
    },
    [dispatch]
  );

  const signOut = useCallback(async () => {
    window.localStorage.removeItem(STORAGE_KEY);
    dispatch({ type: ActionType.SIGN_OUT });
    
    navigate("/", { replace: true });
  }, [dispatch]);

  useEffect(() => {
    if (state.permissions?.id) {
      const moduleId =
        Object.values(modules).find((module) => {
          if (module.regex) {
            const newRegExp = new RegExp(module.path);
            return newRegExp.test(location.pathname);
          } else {
            return module.path === location.pathname;
          }
        }) || {};

      if (moduleId?.id) {
        userApi
          .getParameters(moduleId.params)
          .then((data) => {
            dispatch({
              type: ActionType.PARAMETERS,
              payload: data,
            });
          })
          .catch((e) => {
            dispatch({
              type: ActionType.PARAMETERS,
              payload: null,
            });
          });
      }
    }
  }, [state.permissions]);

  useEffect(() => {
    const moduleId =
      Object.values(modules).find((module) => {
        if (module.regex) {
          const newRegExp = new RegExp(module.path);
          return newRegExp.test(location.pathname);
        } else {
          return module.path === location.pathname;
        }
      }) || {};

    if (moduleId?.id) {
      userApi
        .getPermission(moduleId.id)
        .then((data) => {
          dispatch({
            type: ActionType.PERMISSIONS,
            payload: data,
          });
        })
        .catch((err) => {
          dispatch({
            type: ActionType.PERMISSIONS,
            payload: null,
          });
          navigate("/", { replace: true }); // redirect to login
        });
    } else {
      dispatch({
        type: ActionType.PERMISSIONS,
        payload: null,
      });
    }
  }, [location.pathname, state.user]);

  useInterval(() => {
    checkTokenExpiration();
  }, 30000);

  const checkTokenExpiration = () => {
    const token = window.localStorage.getItem(STORAGE_KEY);
    if (!token) {
      return;
    }
  
    try {
      const decoded = jwt_decode(token);
      const currentTime = Date.now() / 1000;
      const timeUntilExpiration = decoded.exp - currentTime;
  
      if (timeUntilExpiration <= 0) {
        signOut();
      } else if (timeUntilExpiration <= 60) {
        refreshToken();
      }
    } catch (error) {
      signOut();
    }
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        signIn,
        signUp,
        signOut,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const AuthConsumer = AuthContext.Consumer;