import { AnchorLink, ItemTypography, ItemsList, Title } from "../../styles"
import { Stack } from "@mui/material";
import { neutral } from "src/theme/colors";
import ArticleIcon from '@mui/icons-material/Article';

const HELPER_BASE_URL = "https://ajuda.weesu.com.br";

export default function DuvidasMaisFrequentesItemList(){
    return (
        <>
            <Title>Dúvidas mais frequentes</Title>
            <ItemsList>
            <li>
                <AnchorLink
                href={`${HELPER_BASE_URL}/support/solutions/articles/151000209920--weesu-wms-como-adicionar-novos-usu%C3%A1rios`}
                target="_blank"
                >
                <Stack alignItems="center" direction="row" flexWrap="wrap" gap={1}>
                    <ArticleIcon sx={{color: neutral[500]}}/>
                    <ItemTypography color="text.secondary">
                        Como adicionar um novo usuário
                    </ItemTypography>
                </Stack>
                </AnchorLink>
            </li>
            <li>
                <AnchorLink
                href={`${HELPER_BASE_URL}/support/solutions/articles/151000210020--weesu-wms-como-alocar-produtos-no-estoque`}
                target="_blank"
                >
                <Stack alignItems="center" direction="row" flexWrap="wrap" gap={1}>
                    <ArticleIcon sx={{color: neutral[500]}}/>
                    <ItemTypography color="text.secondary">
                    Como alocar produtos no estoque
                    </ItemTypography>
                </Stack>
                </AnchorLink>
            </li>
            <li>
                <AnchorLink
                href={`${HELPER_BASE_URL}/support/solutions/articles/151000210050--weesu-wms-como-movimentar-produtos-no-estoque
`}
                target="_blank"
                >
                <Stack alignItems="center" direction="row" flexWrap="wrap" gap={1}>
                    <ArticleIcon sx={{color: neutral[500]}} />
                    <ItemTypography color="text.secondary">
                    Como movimentar produtos no estoque
                    </ItemTypography>
                </Stack>
                </AnchorLink>
            </li>
            </ItemsList>
        </>
    )
}