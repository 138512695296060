import { Divider, IconButton, List, Popover, Tooltip, Typography, useMediaQuery } from "@mui/material";
import { usePopover } from "src/hooks/use-popover";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { useTheme } from '@mui/material/styles';
import DuvidasMaisFrequentesItemList from "./components/DuvidasMaisFrequentesItemList";
import CentralDuvidasListItem from "./components/CentralDuvidasListItem";
import FaleConoscoListItem from "./components/FaleConoscoListItem";
import { ItemTypography } from "./styles";

export const HelperDropdown = () => {
    const popover = usePopover();
    const theme = useTheme();
    const mobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  
    return (
        <>
            <Tooltip title="Central de ajuda">
                <IconButton ref={popover.anchorRef} onClick={popover.handleOpen}>
                    <HelpOutlineIcon sx={{ fontSize: "24px", color: theme.palette.common.white }} />
                </IconButton>
            </Tooltip>
            <Popover
                elevation={24}
                anchorEl={popover.anchorRef?.current}
                open={popover.open}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: mobile ? "center" : "right",
                }}
                onClose={popover.handleClose}
                sx={{
                    zIndex: theme.zIndex.appBar + 2
                }}
                PaperProps={{
                    style: {
                        margin: 2,    
                    },
                }}
            >
            <DuvidasMaisFrequentesItemList /> 
            <Divider sx={{ mt: 1, mb: 1 }}/>
            <List>
                <CentralDuvidasListItem />
                <Divider sx={{ mt: 1, mb: 1}}/>
                <FaleConoscoListItem />
            </List>
            </Popover>
        </>
    );
  };