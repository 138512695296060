import { experimentalStyled, styled, Typography } from "@mui/material";

export const ItemsList = experimentalStyled("ul")(({ theme }) => ({
    listStyleType: "none",
    margin: 0,
    padding: "0 16px 8px 16px",
    fontSize: "0.875rem",
    "&:hover": {
      color: theme.palette.primary.main, 
    },
    "& li + li": {
      margin: "4px 0",
    },
    lineHeight: 1,
}));

export const AnchorLink = experimentalStyled("a")({
  textDecoration: 'none',
  color: 'inherit',
});

export const ItemTypography = styled(Typography)(({ theme }) => ({
  fontWeight: 400,
  fontSize: '0.875rem',
  lineHeight: 1,
}));

export const Title = experimentalStyled(Typography)({
    fontSize: '1.1rem',
    fontWeight: 600,
    padding: '8px 16px 8px 16px'
});
