import * as React from 'react';

import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import { FormControl, MenuItem, Select, Typography } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { usePagination } from 'src/context/pagination-context';

function TablePaginationActions(props) {
    const theme = useTheme();
    const { page, count, rowsPerPage, onPageChange } = props;

    const handleBackButtonClick = (event) => {
        onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
        onPageChange(event, page + 1);
    };

    const isNextAvailable = count >= rowsPerPage;

    return (
        <Box sx={{ flexShrink: 0, ml: 2.5, display: 'flex', alignItems: 'center' }}>
            <IconButton
                onClick={handleBackButtonClick}
                disabled={page === 0}
                aria-label="previous page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>

            <Typography
                sx={{
                    fontWeight: 'bold',
                    fontSize: '0.85rem',
                    minWidth: '28px',
                    minHeight: '28px',
                    textAlign: 'center',
                    backgroundColor: theme.palette.primary.main,
                    color: 'white',
                    borderRadius: '50%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    padding: '4px',
                }}
            >
                {page + 1}
            </Typography>

            <IconButton
                onClick={handleNextButtonClick}
                aria-label="next page"
                disabled={!isNextAvailable}
            >
                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
        </Box>
    );
}

TablePaginationActions.propTypes = {
    onPageChange: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
};

export default function FakeCustomPaginationActionsTable({
    count,
    page,
    rowsPerPage,
    handleChangePage,
    handleChangeRowsPerPage
}) {
    const currentSizeWidth = window.screen.width;
    const location = useLocation();
    const { setPage } = usePagination();
    const paginationKey = location.pathname;

    const handlePaginationChange = (newPage) => {
        setPage(paginationKey, newPage);
        handleChangePage(newPage);
    }
    
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: currentSizeWidth < 768 ? 'space-around' : 'flex-end',
                mt: '15px'
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: currentSizeWidth < 768 ? 'space-around' : 'center'
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}
                >
                    <Typography
                        variant='body2'
                        sx={{
                            marginRight: 1,
                            display: currentSizeWidth < 768 ? 'none' : 'inherit'
                        }}
                    >
                        Linhas por página:
                    </Typography>
                    <FormControl>
                        <Select
                            value={Number(rowsPerPage ?? 0)}
                            onChange={(event) => handleChangeRowsPerPage(Number(event.target.value ?? 0))}
                            displayEmpty
                            inputProps={{
                                'aria-label': 'Limite de linhas a serem exibidas'
                            }}
                            sx={{
                                height: '32px'
                            }}
                        >
                            <MenuItem value={25}>25</MenuItem>
                            <MenuItem value={50}>50</MenuItem>
                        </Select>
                    </FormControl>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'center',
                        marginX: '24px',
                        display: currentSizeWidth < 768 ? 'none' : 'inherit'
                    }}
                >
                </Box>
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <TablePaginationActions
                    key={'paging'}
                    count={Number(count ?? 0)}
                    page={Number(page ?? 0)}
                    onPageChange={(_, newPage) => handlePaginationChange(Number(newPage ?? 0))}
                    rowsPerPage={Number(rowsPerPage ?? 0)}
                />
            </Box>
        </Box>
    );
}
