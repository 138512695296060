import React from "react";
import { Paper, Table, TableContainer, TableHead, TableRow, TableCell, TableBody, TableFooter, Typography } from "@mui/material";
import { useNotification } from "src/context/notification-context.js";

const DataGrid = (props) => {
  const { notificationHeight } = useNotification();

  const {
    columns,
    data,
    paginateComponent,
    sx,
    enableHover = true,
    onRowClick = null,
    options,
  } = props;

  const sxCell = {
    borderBottom: `1px solid #DFE3E8`,
    minHeight: 50,
    height: "30px",
    lineHeight: 1,
    textAlign: "center",
    position: "static",
    padding: 0,
    borderLeft: 0,
    borderRight: 0,
    paddingRight: "5px",
    paddingLeft: "5px",
  };

  const sxRow = {
    height: "35px",
    "&$selected": {
      backgroundColor: "#FAFAFA",
    },
    "&$hover": {
      "&:hover": {
        backgroundColor: "#FAFAFA",
      },
    },
    padding: 0,
    lineHeight: 0,
    cursor: onRowClick ? "pointer" : "auto",
  };

  const appBarSize = 64;
  return (
    <TableContainer
      sx={{
        ...sx,
        minHeight: options.minBodyHeight ? options.minBodyHeight - (notificationHeight ? notificationHeight - appBarSize : 0) : "100%",
        maxHeight: options.maxBodyHeight ? options.maxBodyHeight - (notificationHeight ? notificationHeight - appBarSize : 0) : "100%",
      }}
      component={Paper}
    >
      <Table sx={{ width: "100%" }} size="small" aria-label="a dense table">
        <TableHead
          sx={{
            top: 0,
            left: 0,
            zIndex: 2,
            position: "sticky",
            backgroundColor: "#31b7bc",
            borderBottom: "3px solid rgb(0, 36, 47)",
            fontFamily: "Montserrat",
          }}
        >
          <TableRow sx={{ width: "100%", height: "50px" }}>
            {columns.map((item, index) => (
              <TableCell
                size="small"
                key={`C${index}`}
                sx={{
                  color: "#FFF",
                  ...sxCell,
                  ...options?.headerStyle,
                  ...item?.style,
                  ...item?.headerStyle,
                }}
              >
                {item.title_actions
                  ? item.title_actions(item, index)
                  : item.title}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody
          sx={{
            fontFamily: "Montserrat",
          }}
        >
          {data?.length > 0 ? (
            data.map((value, rowIndex) => (
              <TableRow
                hover={enableHover}
                key={`V${rowIndex}`}
                sx={{
                  backgroundColor: rowIndex % 2 === 0 ? "#FAFAFA" : "#FFF",
                  ...sxRow,
                  ...options?.rowStyle,
                  ...(() =>
                    typeof options?.rowStyle === "object"
                      ? options?.rowStyle
                      : typeof options?.rowStyle === "function"
                      ? options?.rowStyle(value)
                      : {})(),
                }}
                onClick={(event) => onRowClick && onRowClick(event, value)}
              >
                {columns.map((item, colIndex) => (
                  <TableCell
                    size="small"
                    key={`VC${colIndex}`}
                    sx={{
                      ...sxCell,
                      ...options?.cellStyle,
                      ...item?.style,
                      ...(() =>
                        typeof item?.cellStyle === "object"
                          ? item?.cellStyle
                          : typeof item?.cellStyle === "function"
                          ? item?.cellStyle(value)
                          : {})(),
                    }}
                  >
                    {item.actions
                      ? item.actions(value, rowIndex)
                      : item.render
                      ? item.field
                        ? item.render(value[item.field], rowIndex)
                        : item.render(value, rowIndex)
                      : item.field && value[item.field]}
                  </TableCell>
                ))}
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={columns.length}>
                <Typography sx={{ textAlign: "center" }}>
                  {options?.defaultMessage
                    ? options.defaultMessage
                    : "Não há registros para serem exibidos."}
                </Typography>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
        {paginateComponent && (
          <TableFooter>
            <TableRow>{paginateComponent}</TableRow>
          </TableFooter>
        )}
      </Table>
    </TableContainer>
  );
};

export default DataGrid;