/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Button, Divider, Drawer, List, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Input as InputIcon, ViewInAr, ManageSearch } from "@mui/icons-material";
import { Campaign } from "@mui/icons-material";
import {
  BarChart as BarChartIcon,
  Layers as CadastrosIcon,
  Package,
  CornerDownRight as SubItemIcon,
  Tool,
  Truck as TruckIcon,
} from "react-feather";
import IconReportAnalytics from "src/assets/icons/IconReportAnalytics";
import { useAuth } from "src/hooks";
import { modules } from "../../modules.js";
import NavItem from "./NavItem";
import { Creators as UserCreators } from "src/store/ducks/user";
import { useNotification } from "src/context/notification-context.js";

const useStyles = makeStyles((theme) => ({
  mobileSidebar: {
    display: "none",
    [theme.breakpoints.up("lg")]: {
      display: "block",
    },
  },
  desktopSidebar: {
    display: "none",
    [theme.breakpoints.down("lg")]: {
      display: "block",
    },
  },
  logoutMobileButton: {
    display: "none",
    [theme.breakpoints.down("lg")]: {
      display: "block",
    },
  },
  logoutButton: {
    marginTop: "auto",
    fontWeight: "medium",
    justifyContent: "flex-start",
    letterSpacing: 0,
    textTransform: "none",
    width: "100%",
    height: "48px",
    "& svg": {
      marginRight: "8px",
    },
  },
  newsButton: {
    display: "none",
    [theme.breakpoints.down("lg")]: {
      display: "block",
    },
  }
}));

const MAX_TOPICS_STORED = 10;

const DashboardSidebar = ({ onMobileClose, openMobile }) => {
  const [menu, setMenu] = useState([]);
  const [subitemsVisible, setSubitemsVisible] = useState("");
  const [selectedSubItem, setSelectedSubItem] = useState("");
  const [subSubitemsVisible, setSubSubitemsVisible] = useState("");
  const [selectedSubSubItem, setSelectedSubSubItem] = useState("");
  const { notificationHeight } = useNotification();
  const location = useLocation();

  const { signOut, user } = useAuth();
  const classes = useStyles();

  const [pulse, setPulse] = useState(false);
  const [currentNews, setCurrentNews] = useState(null);

  function verificaPermissoes() {
    setMenu([
      {
        id: modules.home.id,
        href: modules.home.path,
        icon: BarChartIcon,
        title: modules.home.title,
        disabled: false,
        subitems: [],
      },
      {
        id: 2,
        href: "#",
        icon: CadastrosIcon,
        title: "Cadastros",
        disabled: false,
        subitems: [
          {
            id: modules.pickingBox.id,
            href: modules.pickingBox.path,
            icon: SubItemIcon,
            title: modules.pickingBox.title,
            disabled: false,
          },
          {
            id: modules.packaging.id,
            href: modules.packaging.path,
            icon: SubItemIcon,
            title: modules.packaging.title,
            disabled: false,
          },
          // {
          //   id: modules.stockAddress.id,
          //   href: modules.stockAddress.path,
          //   icon: SubItemIcon,
          //   title: modules.stockAddress.title,
          //   disabled: process.env.NODE_ENV !== "development",
          // },
        ],
      },
      {
        id: 3,
        href: "#",
        icon: Package,
        title: "Estoque",
        disabled: false,
        subitems: [
          {
            id: modules.search.id,
            href: modules.search.path,
            icon: ManageSearch,
            title: modules.search.title,
            disabled: false,
            subitems: [
              {
                id: modules.productsListing.id,
                href: modules.productsListing.path,
                icon: SubItemIcon,
                title: modules.search.title,
                description: modules.productsListing.title,
                disabled: false,
              },
              {
                id: modules.quickSearch.id,
                href: modules.quickSearch.path,
                icon: SubItemIcon,
                title: modules.search.title,
                description: modules.quickSearch.title,
                disabled: false,
              },
            ]
          },
          /**
           * Mapeamento foi comentado para não confundir pessoal da usee, mas ficará aqui
           * para o caso de necessidade futura. */
          // {
          //   id: modules.stockMapping.id,
          //   href: modules.stockMapping.path,
          //   icon: SubItemIcon,
          //   title: modules.stockMapping.title,
          //   disabled: process.env.NODE_ENV !== "development",
          // },
          {
            id: modules.receiving.id,
            href: modules.receiving.path,
            icon: ViewInAr,
            title: modules.receiving.title,
            disabled: false,
            subitems: [
              {
                id: modules.receivingReceive.id,
                href: modules.receivingReceive.path,
                icon: SubItemIcon,
                title: modules.receiving.title,
                description: modules.receivingReceive.title,
                disabled: false,
              }
            ],
          },
          {
            id: modules.stockAllocation.id,
            href: modules.stockAllocation.path,
            icon: SubItemIcon,
            title: modules.stockAllocation.title,
            disabled: false,
          },
          {
            id: modules.inventoryMovement.id,
            href: modules.inventoryMovement.path,
            icon: SubItemIcon,
            title: modules.inventoryMovement.title,
            disabled: false,
          },
          {
            id: modules.stockTransfer.id,
            href: modules.stockTransfer.path,
            icon: SubItemIcon,
            title: modules.stockTransfer.title,
            disabled: false,
          },
          {
            id: modules.takeTransfer.id,
            href: modules.takeTransfer.path,
            icon: SubItemIcon,
            title: modules.takeTransfer.title,
            disabled: false,
          },
          // {
          //   id: modules.freeInventory.id,
          //   href: modules.freeInventory.path,
          //   icon: SubItemIcon,
          //   title: modules.freeInventory.title + " (Gerencial)",
          //   disabled: false /*process.env.NODE_ENV !== "development"*/,
          // },
          {
            id: modules.createInventory.id,
            href: modules.createInventory.path,
            icon: SubItemIcon,
            title: modules.createInventory.title,
            disabled: false,
          },
          {
            id: modules.takeInventory.id,
            href: modules.takeInventory.path,
            icon: SubItemIcon,
            title: modules.takeInventory.title,
            disabled: false,
          },
          {
            id: modules.inventoryRequests.id,
            href: modules.inventoryRequests.path,
            icon: SubItemIcon,
            title: modules.inventoryRequests.title,
            disabled: false,
          },
        ],
      },
      {
        id: 4,
        href: "#",
        icon: TruckIcon,
        title: "Expedição",
        disabled: false,
        subitems: [
          {
            id: modules.picking.id,
            href: modules.picking.path,
            icon: SubItemIcon,
            title: modules.picking.title,
            disabled: false,
          },
          {
            id: modules.packingBox.id,
            href: modules.packingBox.path,
            icon: SubItemIcon,
            title: modules.packingBox.title,
            disabled: false,
          },
          // {
          //   id: modules.deliveryVolume.id,
          //   href: modules.deliveryVolume.path,
          //   icon: SubItemIcon,
          //   title: modules.deliveryVolume.title,
          //   disabled: process.env.NODE_ENV !== "development",
          // },
        ],
      },
      {
        id: 5,
        href: "#",
        icon: IconReportAnalytics,
        title: "Relatórios",
        disabled: false,
        subitems: [
          {
            id: modules.reportStockDivergence.id,
            href: modules.reportStockDivergence.path,
            icon: SubItemIcon,
            title: modules.reportStockDivergence.title,
            disabled: false,
          },
          // {
          //   id: modules.reportEmptyAddress.id,
          //   href: modules.reportEmptyAddress.path,
          //   icon: SubItemIcon,
          //   title: modules.reportEmptyAddress.title,
          //   disabled: process.env.NODE_ENV !== "development",
          // },
          // {
          //   id: modules.reportCurveA.id,
          //   href: modules.reportCurveA.path,
          //   icon: SubItemIcon,
          //   title: modules.reportCurveA.title,
          //   disabled: process.env.NODE_ENV !== "development",
          // },
        ],
      },
      {
        id: 6,
        href: "#",
        icon: Tool,
        title: "Sistema",
        disabled: false,
        subitems: [
          {
            id: modules.userControl.id,
            href: modules.userControl.path,
            icon: SubItemIcon,
            title: modules.userControl.title,
            disabled: false,
          },
          // {
          //   id: modules.logView.id,
          //   href: modules.logView.path,
          //   icon: SubItemIcon,
          //   title: modules.logView.title,
          //   disabled: process.env.NODE_ENV !== "development",
          // },
          {
            id: modules.pickingPriority.id,
            href: modules.pickingPriority.path,
            icon: SubItemIcon,
            title: modules.pickingPriority.title,
            disabled: false,
          },
        ],
      },
    ]);
  }

  useEffect(() => {
    verificaPermissoes();
  }, [user]);

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  useEffect(() => {
    const checkNewsStatus = () => {
      const newsUrl = process.env.REACT_APP_NEWS_URL ?? '';
      setCurrentNews(newsUrl);

      try {
        const stored = window.localStorage.getItem('news_versions');
        const topics = stored ? JSON.parse(stored) : [];

        const lastVersionChecked = window.localStorage.getItem('user_last_checked_version') || 'false';
        const isNewVersion = !topics.includes(newsUrl);

        setPulse(isNewVersion || lastVersionChecked === 'false');
      } catch (error) {
        setPulse(false);
      }
    };

    checkNewsStatus();
  }, []);

  const openWMSNews = () => {
    if (!currentNews) return;

    try {
      const stored = window.localStorage.getItem('news_versions');
      let topics = stored ? JSON.parse(stored) : [];

      const lastVersionChecked = window.localStorage.getItem('user_last_checked_version') || 'false';

      if (lastVersionChecked === 'false') {
        window.localStorage.setItem('user_last_checked_version', 'true');
      }

      if (!topics.includes(currentNews)) {
        topics.push(currentNews);

        if (topics.length > MAX_TOPICS_STORED) {
          topics = topics.slice(-MAX_TOPICS_STORED);
        }

        window.localStorage.setItem('news_versions', JSON.stringify(topics));
        UserCreators.getUserLastCheckedVersion();
      }

      setPulse(false);

      window.open(currentNews, '_blank');
    } catch (error) {
      window.open(currentNews, '_blank');
    }
  };

  const content = (
    <Box sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
      <Box display="flex" flexDirection="column" alignItems="center" py={1}>
        <Typography color="textPrimary" variant="h5">
          {user?.name}
        </Typography>
      </Box>
      <Divider />
      <Box>
        <List>
          {menu.map((item) => (
            <NavItem
              disabled={item.disabled}
              subitems={item.subitems}
              href={item.href}
              key={item.title}
              title={item.title}
              icon={item.icon}
              setSubitemsVisible={setSubitemsVisible}
              subitemsVisible={subitemsVisible}
              setSelectedSubItem={setSelectedSubItem}
              selectedSubItem={selectedSubItem}
              setSubSubitemsVisible={setSubSubitemsVisible}
              subSubitemsVisible={subSubitemsVisible}
              setSelectedSubSubItem={setSelectedSubSubItem}
              selectedSubSubItem={selectedSubSubItem}
            />
          ))}
        </List>
        <Box className={classes.logoutMobileButton}>
          <Button className={classes.logoutButton} onClick={signOut}>
            <InputIcon /> Sair
          </Button>
        </Box>
        <Box className={classes.newsButton}>
          <Button className={classes.logoutButton} onClick={openWMSNews}>
            <Box sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              textAlign: 'center'
            }}>
              <Campaign sx={{ zIndex: 999 }} />
              {
                pulse && (
                  <Box
                    sx={{
                      position: "absolute",
                      border: "1px solid transparent",
                      borderRadius: "50%",
                      marginRight: '10px',
                      animation: "pulseBorder 1.5s infinite ease-in-out",
                      "@keyframes pulseBorder": {
                        "0%": {
                          padding: 0,
                          borderWidth: "0px",
                          borderColor: "rgba(255, 0, 0, 1)",
                        },
                        "100%": {
                          padding: 2,
                          borderWidth: "7px",
                          borderColor: "rgba(255, 0, 0, 0)",
                        },
                      },
                    }}
                  />
                )
              }
            </Box>
            Novidades
          </Button>
        </Box>
      </Box>
    </Box>
  );

  return (
    <Box zIndex={1}>
      {!window.location.href.includes("separacao_") && (
        <Box className={classes.desktopSidebar}>
          <Drawer
            anchor="left"
            onClose={onMobileClose}
            open={openMobile}
            variant="temporary"
            PaperProps={{
              style: {
                width: 256,
                top: 0,
                paddingTop: notificationHeight,
                height: '100%',
                transition: "top 0.3s ease",
                overflowX: 'hidden',
                zIndex: 2
              },
            }}
          >
            {content}
          </Drawer>
        </Box>
      )}
      <Box className={classes.mobileSidebar}>
        <Drawer
          anchor="left"
          open
          variant="persistent"
          PaperProps={{
            style: {
              width: 256,
              top: 0,
              paddingTop: notificationHeight,
              height: '100%',
              transition: "top 0.3s ease",
              overflowX: 'hidden',
              zIndex: 2
            },
          }}
        >
          {content}
        </Drawer>
      </Box>
    </Box>
  );
};

DashboardSidebar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

DashboardSidebar.defaultProps = {
  onMobileClose: () => { },
  openMobile: false,
  notificationHeight: 0,
};

export default DashboardSidebar;
