import { ListItem, ListItemButton, ListItemText, Stack } from "@mui/material"
import { neutral, primary } from "src/theme/colors";
import { useAuth } from "src/hooks";
import ThreePOutlinedIcon from '@mui/icons-material/ThreePOutlined';

export default function FaleConoscoListItem(){
  const { user } = useAuth();

  const handleFreshWorksWidget = () => {
    if (window.FreshworksWidget) {
      if (user) {
        window.FreshworksWidget('identify', 'ticketForm', {
          email: user.email,
          name: user.name,
        });
        window.FreshworksWidget('disable', 'ticketForm', ['name', 'email']);
      }
      window.FreshworksWidget('open');
    }
  }

  return (
    <>
      <ListItem disablePadding onClick={handleFreshWorksWidget}>
        <ListItemButton
          sx={{
            pt: 0,
            pb: 0,
            "&:hover": {
              backgroundColor: primary["alpha8"],
            },
          }}
        >
          <Stack alignItems="center" direction="row" flexWrap="wrap" gap={1}>
            <ThreePOutlinedIcon sx={{color: neutral[900]}}/>
            <ListItemText primary="Fale conosco" />
          </Stack>
        </ListItemButton>
      </ListItem>
    </>
  )
}