import { createContext, useContext, useState, useRef, useLayoutEffect, useEffect } from "react";
import { getMobileOperatingSystem } from "src/utils/getUtils";

export const NotificationContext = createContext();
const LOCAL_STORAGE_KEY = 'notificationsMessages';

export const NotificationProvider = ({ children }) => {
  const [notifications, setNotifications] = useState(() => {
    const storedMessages = localStorage.getItem(LOCAL_STORAGE_KEY);
    if (storedMessages && storedMessages !== 'undefined') {
      let storedMessagesObj = JSON.parse(storedMessages);
      if (typeof storedMessagesObj === "string") storedMessagesObj = JSON.parse(storedMessagesObj);
      return storedMessagesObj.map((n, index) => ({
        ...n,
        id: index,
        hidden: n.hidden ?? false
      }));
    }
    return [];
  });

  const notificationSize = (getMobileOperatingSystem() === "unknown" ? 40 : 145);
  const appBarSize = 64;
  const [notificationHeight, setNotificationHeight] = useState((notifications.length * notificationSize) + appBarSize);
  const notificationRef = useRef(null);

  useLayoutEffect(() => {
    if (!notificationRef.current) return;

    const updateHeight = () => {
      const visibleNotifications = notifications.filter((n) => !n.hidden);
      //const height = visibleNotifications.length > 0 ? notificationRef.current.clientHeight : 0;
      const height = visibleNotifications.length > 0 ? (visibleNotifications.length * notificationSize) + appBarSize: appBarSize;
      setNotificationHeight(height);
    };
    setTimeout(updateHeight, 0);
    const observer = new ResizeObserver(updateHeight);
    observer.observe(notificationRef.current);

    return () => observer.disconnect();
  }, [notifications]);

  // 🔥 Atualiza o `notificationHeight = 0` quando todas as notificações forem escondidas
  useEffect(() => {
    if (notifications.length === 0 || notifications.every((n) => n.hidden)) {
      setTimeout(() => {
        setNotificationHeight(appBarSize);
      }, 0);
    }
  }, [notifications]);

  const hideNotification = (id) => {
    setNotifications((prev) =>
      prev.map((n) => (n.id === id ? { ...n, hidden: true } : n))
    );
  };

  return (
    <NotificationContext.Provider
      value={{ notifications, setNotifications, notificationHeight, notificationRef, hideNotification }}
    >
      {children}
    </NotificationContext.Provider>
  );
};

export const useNotification = () => {
  return useContext(NotificationContext);
};
