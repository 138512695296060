import { Campaign } from "@mui/icons-material";
import InputIcon from "@mui/icons-material/Input";
import MenuIcon from "@mui/icons-material/Menu";
import { Box, IconButton, Toolbar, Tooltip, useTheme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import logo from "src/assets/images/logo-weesu.svg";
import { HelperDropdown } from "src/components/application/HelperDropDown";
import { useAuth } from "src/hooks";
import { Creators as UserCreators } from "src/store/ducks/user";

const useStyles = makeStyles((theme) => ({
  togglerSidebar: {
    display: "none",
    [theme.breakpoints.down("lg")]: {
      display: "block",
    },
  },
  headerIcons: {
    display: "none",
    [theme.breakpoints.up("lg")]: {
      display: "block",
    },
  },
}));

const MAX_TOPICS_STORED = 10;

const DashboardNavbar = ({ onMobileNavOpen, ...rest }) => {
  const theme = useTheme();
  const classes = useStyles();
  const { signOut } = useAuth();
  const [pulse, setPulse] = useState(false);
  const [currentNews, setCurrentNews] = useState(null);

  useEffect(() => {
    const checkNewsStatus = () => {
      const newsUrl = process.env.REACT_APP_NEWS_URL ?? '';
      setCurrentNews(newsUrl);

      try {
        const stored = window.localStorage.getItem('news_versions');
        const topics = stored ? JSON.parse(stored) : [];

        const lastVersionChecked = window.localStorage.getItem('user_last_checked_version') || 'false';
        const isNewVersion = !topics.includes(newsUrl);

        setPulse(isNewVersion || lastVersionChecked === 'false');
      } catch (error) {
        setPulse(false);
      }
    };

    checkNewsStatus();
  }, []);

  const openWMSNews = () => {
    if (!currentNews) return;

    try {
      const stored = window.localStorage.getItem('news_versions');
      let topics = stored ? JSON.parse(stored) : [];

      const lastVersionChecked = window.localStorage.getItem('user_last_checked_version') || 'false';

      if (lastVersionChecked === 'false') {
        window.localStorage.setItem('user_last_checked_version', 'true');
      }

      if (!topics.includes(currentNews)) {
        topics.push(currentNews);

        if (topics.length > MAX_TOPICS_STORED) {
          topics = topics.slice(-MAX_TOPICS_STORED);
        }

        window.localStorage.setItem('news_versions', JSON.stringify(topics));
        UserCreators.getUserLastCheckedVersion();
      }
      
      setPulse(false);

      window.open(currentNews, '_blank');
    } catch (error) {
      window.open(currentNews, '_blank');
    }
  };

  return (
    <Box elevation={0} {...rest} sx={{ backgroundColor: theme.palette.weesu.dark_blue, zIndex: theme.zIndex.appBar, color: theme.palette.common.white }}>
      <Toolbar>
        {!window.location.href.includes("separacao_") ? (
          <RouterLink to="/app/home">
            <img src={logo} style={{ width: 150, height: 55 }} alt="" />
          </RouterLink>
        ) : (
          <img src={logo} style={{ width: 150, height: 55 }} alt="" />
        )}
        <Box sx={{ flexGrow: 1 }} />
        <Box className={classes.headerIcons}>
          <Tooltip title="Novidades do WMS">
            <IconButton
              onClick={openWMSNews}
              color="inherit"
            >
              <Campaign sx={{ zIndex: 999 }} />
              {
                pulse && (
                  <Box
                    sx={{
                      position: "absolute",
                      border: "3px solid transparent",
                      borderRadius: "50%",
                      animation: "pulseBorder 1.5s infinite ease-in-out",
                      "@keyframes pulseBorder": {
                        "0%": {
                          padding: 0,
                          borderWidth: "0px",
                          borderColor: "rgba(255, 0, 0, 1)",
                        },
                        "100%": {
                          padding: 2.5,
                          borderWidth: "7px",
                          borderColor: "rgba(255, 0, 0, 0)",
                        },
                      },
                    }}
                  />
                )
              }
            </IconButton>
          </Tooltip>
          <HelperDropdown />
          <Tooltip title="Sair">
            <IconButton onClick={signOut} color="inherit">
              <InputIcon />
            </IconButton>
          </Tooltip>
        </Box>
        {!window.location.href.includes("separacao_") && (
          <Box className={classes.togglerSidebar}>
            <Tooltip title="Novidades do WMS">
            <IconButton
              onClick={openWMSNews}
              color="inherit"
            >
              <Campaign sx={{ zIndex: 999 }} />
              {
                pulse && (
                  <Box
                    sx={{
                      position: "absolute",
                      border: "3px solid transparent",
                      borderRadius: "50%",
                      animation: "pulseBorder 1.5s infinite ease-in-out",
                      "@keyframes pulseBorder": {
                        "0%": {
                          padding: 0,
                          borderWidth: "0px",
                          borderColor: "rgba(255, 0, 0, 1)",
                        },
                        "100%": {
                          padding: 2.5,
                          borderWidth: "7px",
                          borderColor: "rgba(255, 0, 0, 0)",
                        },
                      },
                    }}
                  />
                )
              }
            </IconButton>
          </Tooltip>
          <HelperDropdown />
            <Tooltip title="Menu">
              <IconButton color="inherit" onClick={onMobileNavOpen}>
                <MenuIcon />
              </IconButton>
            </Tooltip>
          </Box>
        )}
      </Toolbar>
      <div
        style={{
          height: "3px",
          position: "absolute",
          width: "100%",
          left: "0",
          backgroundImage: `linear-gradient(to right, #F4F6F8, #42B7BC)`,
        }}
      ></div>
    </Box>
  );
};

DashboardNavbar.propTypes = {
  onMobileNavOpen: PropTypes.func,
};

export default DashboardNavbar;