import { createContext, useContext, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

const PaginationContext = createContext();

export const PaginationProvider = ({ children }) => {
  const location = useLocation();

  const [paginationState, setPaginationState] = useState(() => {
    const savedPagination = localStorage.getItem("paginationState");
    return savedPagination ? JSON.parse(savedPagination) : {};
  });

  useEffect(() => {
    const lastRoute = localStorage.getItem("lastRoute");

    if (lastRoute && lastRoute !== location.pathname) {
      setPaginationState({});
      localStorage.removeItem("paginationState");
      localStorage.removeItem("inventory_filters");
    }

    localStorage.setItem("lastRoute", location.pathname);
    localStorage.setItem("paginationState", JSON.stringify(paginationState));

  }, [location.pathname, paginationState]);

  const setPage = (key, page, limit) => {
    if (!key) {
      console.error("🚨 setPage chamado com key inválida!", key, page);
      return;
    }
    setPaginationState((prev) => {
      const updatedState = {
        ...prev,
        [key]: { page, limit },
      };
      localStorage.setItem("paginationState", JSON.stringify(updatedState));
      return updatedState;
    });
  };

  const getPage = (key) => {
    return paginationState[key]?.page ?? 0;
  };

  const getLimit = (key, defaultLimit = 25) => {
    return paginationState[key]?.limit ?? defaultLimit;
  };

  return (
    <PaginationContext.Provider value={{ setPage, getPage, getLimit }}>
      {children}
    </PaginationContext.Provider>
  );
};

export const usePagination = () => useContext(PaginationContext);