import axios from 'axios';

const baseUrl = () => {
  if (process.env.NODE_ENV !== 'development') {    
    return process.env.REACT_APP_URL_CORE_PRODUCTION
  } else {
    return process.env.REACT_APP_URL_CORE_DEVELOPMENT
  }
}

const http = axios.create({ baseURL: baseUrl(), timeout: 5000});

export default http;