import { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, IconButton, Tooltip, Typography, Zoom, Collapse } from '@mui/material';
import { ArrowDownward, ArrowDropDown, ArrowRight, ArrowUpward, DragHandle } from '@mui/icons-material';
import ScrollBar from 'react-perfect-scrollbar';
import { DataGridPagination } from 'src/components';
//import { debounce } from 'lodash';

DataGridNext.propTypes = {
    columns: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string.isRequired, // ID da coluna e chave do objeto -> Usado para identificar a coluna internamente
        label: PropTypes.string.isRequired, // Label da coluna -> Texto que será exibido no cabeçalho da coluna
        headerStyle: PropTypes.shape({ // Estilos do cabeçalho da coluna
            align: PropTypes.oneOf(['left', 'center', 'right']), // Alinhamento do texto na coluna -> left, center, right
            widthPercentage: PropTypes.number.isRequired, // Largura da coluna em porcentagem -> 100% = 100 | 25% = 25
            showStartBorder: PropTypes.bool, // Se a borda esquerda do cabeçalho será exibida
            showEndBorder: PropTypes.bool, // Se a borda direita do cabeçalho será exibida
        }).isRequired,
        headerProperties: PropTypes.shape({ // Estilos da coluna
            formatted: PropTypes.bool, // Se o valor da coluna será formatado
            format: PropTypes.arrayOf(PropTypes.string), // Formato do valor da coluna -> date, currency (só funciona se formatted for true)
            sortable: PropTypes.bool, // Se a coluna pode ser ordenada
            currentSort: PropTypes.string, // Estado atual da ordenação da coluna -> asc, desc, eq (só funciona se sortable for true)
        }),
        cellStyle: PropTypes.shape({ // Estilos da célula da coluna (entenda como linha da tabela)
            align: PropTypes.oneOf(['left', 'center', 'right']), // Alinhamento do texto na célula -> left, center, right
        }),
        cellProperties: PropTypes.shape({ // Propriedades da célula da coluna (entenda como linha da tabela)
            formatted: PropTypes.bool, // Se o valor da célula será formatado
            format: PropTypes.oneOf(['date', 'currency']), // Formato do valor da célula -> date, currency (só funciona se formatted for true)
            mapping: PropTypes.object, // Mapeamento do valor da célula -> { 'valor1': 'valor2' }
            pass: PropTypes.bool, // Se a coluna irá ser "mapeada" de acordo com os dados recebidos -> Se true, o valor da coluna poderá ser uma função feita no callback (ex: callback: (row) => row.codigo) (irá exibir os códigos)
        }),
        callback: PropTypes.func, // Função que será executada quando o a célula possuir o pass como true -> (ex: callback: (row) => row.codigo) (irá exibir os códigos)
    })).isRequired,
    detailedRow: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string.isRequired,
        item: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
        isList: PropTypes.bool,
        isConditional: PropTypes.bool
    })),
    data: PropTypes.any, // Dados da tabela -> Array de objetos
    onSort: PropTypes.func, // Função que será executada quando a coluna possuir o sortable como true -> (ex: onSort: (sortState) => console.log(sortState)) (irá exibir o estado atual da ordenação)
    actions: PropTypes.arrayOf(PropTypes.shape({ // Ações da tabela -> Array de objetos
        id: PropTypes.string.isRequired, // ID da ação -> Texto que será exibido na ação
        label: PropTypes.string.isRequired, // Label da ação -> Texto que será exibido na ação
        type: PropTypes.string.isRequired, // Tipo da ação -> button, link, icon (só funciona se actions for true)
        style: PropTypes.shape({
            color: PropTypes.string,
            backgroundColor: PropTypes.string,
            onHover: PropTypes.shape({
                color: PropTypes.string,
                backgroundColor: PropTypes.string,
            }),
        }),
        icon: PropTypes.element,
        onClick: PropTypes.func.isRequired,
        isConditional: PropTypes.bool,
        param: PropTypes.objectOf(PropTypes.any)
    })),
    configuration: PropTypes.shape({
        showStartSelection: PropTypes.bool,
        showStartDetailedRowButton: PropTypes.bool,
    }),
}

export default function DataGridNext({ columns, data, actions, configuration, detailedRow, onSort }) {
    /* const [sortState, setSortState] = useState(
        columns.reduce((acc, column) => ({ ...acc, [column.id]: column?.headerProperties?.currentSort || 'eq' }), {})
    );
    const [zoomState, setZoomState] = useState(
        columns.reduce((acc, column) => ({ ...acc, [column.id]: true }), {})
    ); */
    const [detailedRowState, setDetailedRowState] = useState(
        data.reduce((acc, row) => ({ ...acc, [row.codigo]: false }), {})
    );

    /* const debouncedSort = useMemo(() => debounce(() => onSort(sortState), 1500), [onSort, sortState]);

    useEffect(() => {
        debouncedSort();

        return () => {
            debouncedSort.cancel()
        }
    }, [sortState]);

    const handleSort = (columnId) => {
        setSortState((prevSort) => {
            const newSort = prevSort[columnId] === 'asc' ? 'desc' : prevSort[columnId] === 'desc' ? 'eq' : 'asc';
            
            return { ...prevSort, [columnId]: newSort };
        });
    };

    const handleColumnZoom = (columnId) => {
        setZoomState((prev) => ({ ...prev, [columnId]: false }));
        setTimeout(() => {
            setZoomState((prev) => ({ ...prev, [columnId]: true }));
        }, 100);
    }; */

    return (
        <Box 
            sx={{
                flex: 1,
                minHeight: 0,
                position: 'relative',
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    height: '50px',
                    borderRadius: '4px 4px 0px 0px',
                    backgroundColor: "#31b7bc",
                    borderBottom: "3px solid rgb(0, 36, 47)",
                    fontSize: "0.875rem",
                    lineHeight: "1.5",
                    fontFamily: "Montserrat",
                }}
            >
                {
                    configuration?.showStartDetailedRowButton && <Box sx={{ width: '24px', height: '100%' }} />
                }
                {
                    columns.map((column) => (
                        <Box 
                            key={column.id}
                            sx={{
                                width: `${column.headerStyle.widthPercentage}%`,
                                height: '100%',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: column.headerStyle.align || 'left',
                                paddingX: 2,
                                fontWeight: 'bold',
                            }}
                        >
                            <Typography
                                sx={{
                                    color: 'white',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis'
                                }}
                            >
                                {
                                    column.label
                                }
                            </Typography>
                            {/* {
                                column.headerProperties?.sortable && (
                                    <IconButton
                                        size="small"
                                        onClick={() => { handleSort(column.id); handleColumnZoom(column.id); }}
                                    >
                                        <Zoom
                                            in={zoomState[column.id]}
                                        >
                                            {
                                                sortState[column.id] === 'asc' 
                                                    ?   <ArrowUpward
                                                            sx={{
                                                                fontSize: '20px',
                                                                color: '#279296'
                                                            }}
                                                        />
                                                    :   sortState[column.id] === 'desc'
                                                        ?   <ArrowDownward 
                                                                sx={{
                                                                    fontSize: '20px',
                                                                    color: '#279296'
                                                                }}
                                                            />
                                                        :   <DragHandle 
                                                                sx={{
                                                                    fontSize: '20px',
                                                                    color: '#279296'
                                                                }} 
                                                            />
                                            }
                                        </Zoom>
                                    </IconButton>
                                )
                            } */}
                        </Box>
                    ))
                }
                {
                    actions
                    &&  <Box
                            sx={{
                                width: '10%',
                                height: '100%',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                paddingX: 2,
                                fontWeight: 'bold',
                            }}
                        >
                            <Typography 
                                sx={{
                                    color: 'white'
                                }}
                            >
                                Ações
                            </Typography>
                        </Box>
                }
            </Box>
            <Box
                sx={{
                    height: 'calc(100% - 25px)',
                    overflow: 'auto',
                    position: 'absolute',
                    width: '100%',
                    fontSize: '0.875rem',
                    fontWeight: 400,
                    lineHeight: 1,
                }}
            >
                {
                    data.length > 0
                        ?   
                            <ScrollBar>
                                {
                                    data.map((row, rowIndex) => (
                                        <Box key={rowIndex}>
                                            <Box
                                                key={rowIndex}
                                                sx={{
                                                    borderBottom: `1px solid #DFE3E8`,
                                                    minHeight: 50,
                                                    height: "30px",
                                                    lineHeight: 1,
                                                    textAlign: "center",
                                                    padding: 0,
                                                    borderLeft: 0,
                                                    borderRight: 0,
                                                    paddingRight: "5px",
                                                    paddingLeft: "5px",
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    width: '100%',
                                                    backgroundColor: rowIndex % 2 === 0 ? "#FAFAFA" : "#FFF",
                                                    "&$selected": {
                                                        backgroundColor: "#FAFAFA",
                                                    },
                                                    "&$hover": {
                                                        "&:hover": {
                                                            backgroundColor: "#FAFAFA",
                                                        },
                                                    },
                                                    '&:hover': {
                                                        backgroundColor: "#FAFAFA",
                                                    }
                                                }}
                                            >
                                                {
                                                    configuration?.showStartDetailedRowButton
                                                    &&  <Box
                                                            sx={{
                                                                width: '24px',
                                                                height: '100%',
                                                            }}
                                                        >
                                                            <IconButton 
                                                                onClick={() => setDetailedRowState((prev) => ({ ...prev, [row.codigo]: !prev[row.codigo] }))}
                                                            >
                                                                {
                                                                    detailedRowState[row.codigo]
                                                                        ?   <ArrowDropDown 
                                                                                sx={{
                                                                                    color: '#279296'
                                                                                }}
                                                                            /> 
                                                                        :   <ArrowRight 
                                                                                sx={{
                                                                                    color: '#279296'
                                                                                }}
                                                                            />
                                                                }
                                                            </IconButton>
                                                        </Box>
                                                }
                                                {
                                                    columns.map((column) => (
                                                        <Box
                                                            key={column.id}
                                                            sx={{
                                                                width: `${column.headerStyle.widthPercentage}%`,
                                                                display: 'flex',
                                                                justifyContent: column.cellStyle.align || 'left',
                                                                alignItems: 'center',
                                                                paddingX: 2,
                                                                lineHeight: 1,
                                                                textAlign: "center",
                                                                padding: 0,
                                                                borderLeft: 0,
                                                                borderRight: 0,
                                                                paddingRight: "5px",
                                                                paddingLeft: "5px",
                                                                flexDirection: 'row',
                                                                "&$selected": {
                                                                    backgroundColor: "#FAFAFA",
                                                                },
                                                                "&$hover": {
                                                                    "&:hover": {
                                                                        backgroundColor: "#FAFAFA",
                                                                    },
                                                                },
                                                                '&:hover': {
                                                                    backgroundColor: "#FAFAFA",
                                                                }
                                                            }}
                                                        >
                                                            <Typography sx={{ fontWeight: 400, fontSize: '0.875rem' }}>
                                                                {
                                                                    (() => {
                                                                        let value = row[column.id];
                                                                        if (column?.cellProperties?.mapping) value = column.cellProperties.mapping[value];
                                                                        if (column?.cellProperties?.formatted) {
                                                                            if (column.cellProperties.format.includes('date')) value = new Date(value).toLocaleDateString('pt-BR');
                                                                            if (column.cellProperties.format.includes('currency')) value = new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(value);
                                                                        }
                                                                        return value;
                                                                    })()
                                                                }
                                                            </Typography>
                                                        </Box>
                                                    ))
                                                }
                                                {
                                                    actions
                                                    && (
                                                        <Box
                                                            sx={{
                                                                width: '10%',
                                                                height: '100%',
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                justifyContent: 'center',
                                                                padding: 2,
                                                                gap: 1
                                                            }}
                                                        >
                                                            {
                                                                actions.map((action) => (
                                                                    <Tooltip
                                                                        title={action.label}
                                                                        key={action.id}
                                                                    >
                                                                        <IconButton
                                                                            style={{ 
                                                                                ...action.style,
                                                                                '&:hover': {
                                                                                    ...action.style?.onHover
                                                                                },
                                                                                ...(action.isConditional && row[action.param.key] !== action.param.value && {
                                                                                    opacity: 0.3,
                                                                                    pointerEvents: 'none',
                                                                                }),
                                                                            }}
                                                                            onClick={() => action.onClick(row, action.id)}
                                                                        >
                                                                            {
                                                                                action.icon
                                                                            }
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                ))
                                                            }
                                                        </Box>
                                                    )
                                                }
                                            </Box>
                                            <Collapse
                                                in={detailedRowState[row.codigo]}
                                            >
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        width: '100%',
                                                        backgroundColor: rowIndex % 2 === 0 ? "#FAFAFA" : "#FFF",
                                                        padding: 2,
                                                        borderBottom: `1px solid #DFE3E8`,
                                                        lineHeight: 1,
                                                        borderLeft: 0,
                                                        borderRight: 0,
                                                        "&$selected": {
                                                            backgroundColor: "#FAFAFA",
                                                        },
                                                        "&$hover": {
                                                            "&:hover": {
                                                                backgroundColor: "#FAFAFA",
                                                            },
                                                        },
                                                        '&:hover': {
                                                            backgroundColor: "#FAFAFA",
                                                        }
                                                    }}
                                                >
                                                    {
                                                        detailedRow.map((detailedItem) => {
                                                            const elements = detailedItem.isList
                                                                ? row[detailedItem.id]?.map((details) => details[detailedItem.item]) || []
                                                                : [row[detailedItem.item]]

                                                            return (
                                                                <Box
                                                                    key={detailedItem.id}
                                                                    sx={{
                                                                        marginBottom: 2,
                                                                        display: detailedItem.isConditional
                                                                            ? elements.length > 0 && elements[0] !== null
                                                                                ? 'inline'
                                                                                : 'none'
                                                                            : 'inline'
                                                                    }}
                                                                >
                                                                    <Typography
                                                                        sx={{
                                                                            fontWeight: 'bold',
                                                                            marginBottom: 1,
                                                                            fontSize: '0.875rem'
                                                                        }}
                                                                    >
                                                                        {detailedItem.label}
                                                                    </Typography>
                                                                    {
                                                                        elements.map((element, index) => (
                                                                            <Typography key={index} sx={{fontSize: '0.875rem', fontWeight: 400}}>
                                                                                {element}
                                                                            </Typography>
                                                                        ))
                                                                    }
                                                                </Box>
                                                            )
                                                        })
                                                    }
                                                </Box>
                                            </Collapse>
                                        </Box>
                                    ))
                                }
                            </ScrollBar>
                        :   <Box
                                sx={{
                                    width: '100%',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    p: 1
                                }}
                            >
                                <Typography sx={{fontSize: '0.875rem', fontWeight: 400}}>
                                    Nenhum resultado encontrado para exibição.
                                </Typography>
                            </Box>
                }
            </Box>
        </Box>
    );
}
