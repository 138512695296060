import queryString from "query-string";
import { httpwmsapi } from "src/services";

// Action Types
export const Types = {
    REQUEST_INVENTORY_LOADING: "request_inventory/loading",
    REQUEST_INVENTORY_SET_LIST: "request_inventory/set/list",
    REQUEST_INVENTORY_SET_PAGING: "request_inventory/set/paging",
    REQUEST_INVENTORY_SET_ACTION_DATA: "request_inventory/set/action_data",
};

// Reducer
const initialState = {
    loading: false,
    request_inventory_list: [],
    request_inventory_paging: { limit: 25, offset: 0, total: 0 },
    action_data: {
        row: null,
        actionId: null,
    },
};

// Action Creators
export default function reducer(state = initialState, action) {
    switch (action.type) {
        case Types.REQUEST_INVENTORY_LOADING:
            return { ...state, loading: action.payload };
        case Types.REQUEST_INVENTORY_SET_LIST:
            return { ...state, request_inventory_list: action.payload };
        case Types.REQUEST_INVENTORY_SET_PAGING:
            return { ...state, request_inventory_paging: action.payload };
        case Types.REQUEST_INVENTORY_SET_ACTION_DATA:
            return { ...state, action_data: action.payload };
        default:
            return state;
    }
}

export const Creators = {
    requestInventoryLoading: (value) => ({ type: Types.REQUEST_INVENTORY_LOADING, payload: value }),
    requestInventorySetList: (value) => ({ type: Types.REQUEST_INVENTORY_SET_LIST, payload: value }),
    requestInventorySetPaging: (value) => ({ type: Types.REQUEST_INVENTORY_SET_PAGING, payload: value }),
    requestInventorySetActionData: (value) => ({ type: Types.REQUEST_INVENTORY_SET_ACTION_DATA, payload: value }),
    sendRequestInventoryWhenAddressHasNoItems: (body) => {
        return new Promise((resolve, reject) => {
            httpwmsapi
                .post(`/v1/inventario-solicitacoes/solicitar-inventario-por-falta-de-estoque`, body, { timeout: 1000 * 20 })
                .then(({ data }) => resolve(data))
                .catch((error) => reject(error));
        });
    },
    getInventoryRequest: (filters) => {
        const qry = queryString.stringify(filters, { arrayFormat: "index" });
        return new Promise((resolve, reject) => {
            httpwmsapi
                .get(`/v1/inventario-solicitacoes/solicitacoes?${qry}`, { timeout: 1000 * 20 })
                .then(({ data }) => resolve(data))
                .catch((error) => reject(error));
        });
    },
    sendApproveToRequestInventory: (body) => {
        return new Promise((resolve, reject) => {
            httpwmsapi
                .post(`/v1/inventario-solicitacoes/aprovar-solicitacao`, body, { timeout: 1000 * 20 })
                .then(({ data }) => resolve(data))
                .catch((error) => reject(error));
        });
    },
    sendCancelToRequestInventory: (body) => {
        return new Promise((resolve, reject) => {
            httpwmsapi
                .post(`/v1/inventario-solicitacoes/cancelar-solicitacao`, body, { timeout: 1000 * 20 })
                .then(({ data }) => resolve(data))
                .catch((error) => reject(error));
        });
    }
};
