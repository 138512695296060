import { httpfb, httpwmsapi } from "src/services";
import queryString from "query-string";

// Action Types
export const Types = {
    PRODUCT_LOADING:'product/loading',
    PRODUCT_SET_DATA:'product/set/data',
    PRODUCTS_SET_DATA:'products/set/data',
    PRODUCTS_SET_PAGING:'products/set/paging',
    PRODUCTS_SET_FILTER: 'products/set/filter',
    PRODUCTS_SET_FILTER_CHIP: 'products/set/filter_chip',
    PRODUCTS_SET_ORDERS: 'products/set/orders',
    PRODUCTS_SET_ORDER: 'products/set/order',
}

// Reducer
const initialState = {
    loading: false,
    product: {},
    products: [],
    products_paging: { limit: 25, offset: 0, total: 0},
    products_filters: [],
    products_filters_chip:[],
    products_orders: [],
    products_order:{ column: "name", order: "asc" },
};

// Action Creators
export default function reducer(state = initialState, action) {
    switch (action.type) {
        case Types.PRODUCT_LOADING:
            return { ...state, loading: action.payload };
        case Types.PRODUCT_SET_DATA:
            return { ...state, product: action.payload };
        case Types.PRODUCTS_SET_DATA:
            return { ...state, products: action.payload };
        case Types.PRODUCTS_SET_PAGING:
            return { ...state, products_paging: action.payload };
        case Types.PRODUCTS_SET_FILTER:
            return { ...state, products_filters: action.payload };
        case Types.PRODUCTS_SET_FILTER_CHIP:
            return { ...state, products_filters_chip: action.payload };
        case Types.PRODUCTS_SET_ORDERS:
            return { ...state, products_orders: action.payload };
        case Types.PRODUCTS_SET_ORDER:
            return { ...state, products_order: action.payload };
      default:
        return state;
    }
}

export const Creators = {
    productSetOrders: orders => ({ type: Types.PRODUCTS_SET_ORDERS, payload: orders }),
    productSetOrder:  order => ({ type: Types.PRODUCTS_SET_ORDER, payload: order }),
    productSetData: product => ({ type: Types.PRODUCT_SET_DATA, payload: product }),
    productsSetData: products => ({ type: Types.PRODUCTS_SET_DATA, payload: products }),
    productsSetPaging: products_paging => ({ type: Types.PRODUCTS_SET_PAGING, payload: products_paging }),
    productsSetFilters: products_filters => ({ type: Types.PRODUCTS_SET_FILTER, payload: products_filters }),
    productsSetFiltersChip: products_filters_chip => ({ type: Types.PRODUCTS_SET_FILTER_CHIP, payload: products_filters_chip }),
    productLoading: value => ({ type: Types.PRODUCT_LOADING, payload: value }),
    getProdutcts: (filters={}) => {
        const qry = queryString.stringify(filters, { arrayFormat: "index"}).replaceAll('%5B', '[').replaceAll('%5D', ']').replaceAll('physical%20quantity', 'physical_quantity')
        return new Promise( (resolve, reject) => {
            httpfb.get(`/products/search?${qry}`, {timeout: 1000 * 50})
                .then(({ data }) => resolve(data)).catch( error => reject(error))
        })
    },
    getProdutctId: id => {
        return new Promise( (resolve, reject) => {
            httpfb.get(`/products/${id}?include_variations=true`, { timeout: 15 * 1000 })
                .then(({ data }) => resolve(data)).catch( error => reject(error))
        })
    },
    sendSincronizacaoProdutoEstoque: (id) => async (dispatch) => {
        dispatch(Creators.productLoading(true));

        try {
            const { data } = await httpwmsapi.post(`/v1/produtos/${id}`, { timeout: 1000 * 60 });
            dispatch(Creators.productLoading(false));
            return data;
        } catch (error) {
            dispatch(Creators.productLoading(false));
            throw error;
        }
    },
}