/* eslint-disable no-sequences */
/* eslint-disable no-unused-expressions */
import { createContext, useContext, useEffect, useState } from 'react';

const FreshworksWidgetContext = createContext();

export const useFreshWorksWidget = () => useContext(FreshworksWidgetContext);

const FreshworksWidgetProvider = ({ children }) => {
    const [isFreshWorksWidgetVisible, setIsFreshWorksWidgetVisible] = useState(() => {
        const savedVisibility = localStorage.getItem('isFreshWorksWidgetVisible');
        return savedVisibility === 'true';
    });

    const handleSwitchChange = (isVisible) => {
        setIsFreshWorksWidgetVisible(isVisible);
        localStorage.setItem('isFreshWorksWidgetVisible', isVisible.toString());
        if (isVisible) {
            showFreshWorksWidget();
        } else {
            hideFreshWorksWidget();
        }
    };

    const showFreshWorksWidget = () => {
        if (window.FreshworksWidget) {
            window.FreshworksWidget('show', 'launcher');
        }
        
    };

    const hideFreshWorksWidget = () => {
        if (window.FreshworksWidget) {
            window.FreshworksWidget('hide', 'launcher');
        }
    };

    useEffect(() => {
        window.fwSettings = { 'widget_id': 151000004987 };
        !function() {
            if ("function" != typeof window.FreshworksWidget) {
                var n = function() {
                    n.q.push(arguments);
                };
                n.q = [], window.FreshworksWidget = n;
            }
        }();
        const script = document.createElement('script');
        script.src = 'https://widget.freshworks.com/widgets/151000004987.js';
        script.async = true;
        script.defer = true;
        script.onload = () => {
            if (typeof window.FreshworksWidget === 'function') {
                window.FreshworksWidget('hide', 'launcher');
            }
        };
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
            if (typeof window.FreshworksWidget === 'function') {
                window.FreshworksWidget('hide', 'launcher');
            }
        };
    }, []);

  return (
      <FreshworksWidgetContext.Provider value={{
          showFreshWorksWidget: () => handleSwitchChange(true),
          hideFreshWorksWidget: () => handleSwitchChange(false),
          isFreshWorksWidgetVisible,
      }}>
          {children}
      </FreshworksWidgetContext.Provider>
  );
};

export { FreshworksWidgetProvider };
