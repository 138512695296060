import { useState } from "react";
import { experimentalStyled, useTheme } from "@mui/material";
import DashboardNavbar from "./DashboardNavbar";
import DashboardSidebar from "./DashboardSidebar";
import { Outlet } from "react-router-dom";
import { useNotification } from "src/context/notification-context";
import { TopNotificationAlert } from "src/components/application/TopNotificationAlert";


const DashboardLayoutRoot = experimentalStyled("div")(({ theme }) => ({
  backgroundColor: "#f2f4f6",
  display: "flex",
  height: "100%",
  overflow: "hidden",
  width: "100%",
  flexDirection: "column",
}));

const NotificationContainer = experimentalStyled("div")(({ theme }) => ({
  position: "relative",
  top: 0,
  left: 0,
  width: "100%",
  textAlign: "left",
  zIndex: theme.zIndex.appBar,
  overflow: "hidden",
  display: "flex",
  flexDirection: "column",
  [theme.breakpoints.down("md")]: {
    maxHeight: '290px',
    overflowY: 'auto',
  }
}));

const DashboardLayoutWrapper = experimentalStyled("div")(
  ({ theme, notificationHeight }) => ({
    display: "flex",
    flex: "1 1 auto",
    overflow: "hidden",
    transition: "padding-top 0.3s ease",
    [theme.breakpoints.up("lg")]: {
      paddingLeft: 256,
    },
  })
);

const DashboardLayoutContainer = experimentalStyled("div")({
  display: "flex",
  flex: "1 1 auto",
  overflow: "hidden",
});

const DashboardLayoutContent = experimentalStyled("div")({
  flex: "1 1 auto",
  height: "100%",
  overflow: "auto",
});

const DashboardLayout = () => {
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);
  const { notifications, notificationRef } = useNotification();
  
  return (
    <DashboardLayoutRoot>
      {notifications && notifications.length > 0 && notifications.some((n) => !n.hidden) && (
        <NotificationContainer ref={notificationRef}>
          {notifications.map((notification) => (
            <TopNotificationAlert key={notification.id} notification={notification} />
          ))}
        </NotificationContainer>
      )}

      <DashboardNavbar onMobileNavOpen={() => setMobileNavOpen(true)} />
      <DashboardSidebar onMobileClose={() => setMobileNavOpen(false)} openMobile={isMobileNavOpen} />

      <DashboardLayoutWrapper>
        <DashboardLayoutContainer>
          <DashboardLayoutContent>
            <Outlet />
          </DashboardLayoutContent>
        </DashboardLayoutContainer>
      </DashboardLayoutWrapper>
    </DashboardLayoutRoot>
  );
};

export default DashboardLayout;