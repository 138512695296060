import { httpwmsapi } from "src/services";
import queryString from "query-string";

// Action Types
export const Types = {
    RECEIVING_LOADING: 'receiving/loading',

    RECEIVING_SET_DATA: 'receiving/set/data',
    RECEIVINGS_SET_DATA: 'receivings/set/data',
    RECEIVINGS_SET_PAGING: 'receivings/set/paging',
    RECEIVINGS_SET_FILTER: 'receivings/set/filter',

    RECEIVING_PRODUCT_SET_DATA: 'receiving/set/products/data',
    RECEIVING_PRODUCTS_SET_DATA: 'receivings/set/products/data',
    RECEIVING_PRODUCTS_SET_PAGING: 'receivings/set/products/paging',
    RECEIVING_PRODUCTS_SET_FILTER: 'receivings/set/products/filter'
}

// Reducer
const initialState = {
    loading: false,
    receiving: {},
    receivings: [],
    receivings_paging: { limit: 25, offset: 0, total: 0, page: 0 },
    receivings_filters: [],

    receiving_product: {},
    receiving_products: [],
    receiving_products_paging: { limit: 25, offset: 0, total: 0, page: 0 },
    receiving_products_filters: []
};

// Action Creators
export default function reducer(state = initialState, action) {
    switch (action.type) {
        case Types.RECEIVING_LOADING:
            return { ...state, loading: action.payload };
        case Types.RECEIVING_SET_DATA:
            return { ...state, receiving: action.payload };
        case Types.RECEIVINGS_SET_DATA:
            return { ...state, receivings: action.payload };
        case Types.RECEIVINGS_SET_PAGING:
            return { ...state, receivings_paging: action.payload };
        case Types.RECEIVINGS_SET_FILTER:
            return { ...state, receivings_filters: action.payload };
        case Types.RECEIVING_PRODUCT_SET_DATA:
            return { ...state, receiving_product: action.payload };
        case Types.RECEIVING_PRODUCTS_SET_DATA:
            return { ...state, receiving_products: action.payload };
        case Types.RECEIVING_PRODUCTS_SET_PAGING:
            return { ...state, receiving_products_paging: action.payload };
        case Types.RECEIVING_PRODUCTS_SET_FILTER:
            return { ...state, receiving_products_filters: action.payload };
        default:
            return state;
    }
}

export const Creators = {
    receivingLoading: value => ({ type: Types.RECEIVING_LOADING, payload: value }),
    receivingSetData: receiving => ({ type: Types.RECEIVING_SET_DATA, payload: receiving }),
    receivingsSetData: receivings => ({ type: Types.RECEIVINGS_SET_DATA, payload: receivings }),
    receivingsSetPaging: receivings_paging => ({ type: Types.RECEIVINGS_SET_PAGING, payload: receivings_paging }),
    receivingsSetFilters: receivings_filters => ({ type: Types.RECEIVINGS_SET_FILTER, payload: receivings_filters }),
    receivingProductSetData: receivingProduct => ({ type: Types.RECEIVING_PRODUCT_SET_DATA, payload: receivingProduct }),
    receivingProductsSetData: receivingProducts => ({ type: Types.RECEIVING_PRODUCTS_SET_DATA, payload: receivingProducts }),
    receivingProductsSetPaging: receivingProducts_paging => ({ type: Types.RECEIVING_PRODUCTS_SET_PAGING, payload: receivingProducts_paging }),
    receivingProductsSetFilters: receivingProducts_filters => ({ type: Types.RECEIVING_PRODUCTS_SET_FILTER, payload: receivingProducts_filters }),

    getReceivings: (filters = {}) => {
        const qry = queryString.stringify(filters, { arrayFormat: "index" }).replaceAll('%5B', '[').replaceAll('%5D', ']')
        return new Promise((resolve, reject) => {
            httpwmsapi.get(`/v1/recebimento/?${qry}`, { timeout: 1000 * 50 })
                .then(({ data }) => resolve(data)).catch(error => reject(error))
        })
    },

    getReceivingProducts: (filters = {}) => {
        const qry = queryString.stringify(filters, { arrayFormat: "index" }).replaceAll('%5B', '[').replaceAll('%5D', ']')
        return new Promise((resolve, reject) => {
            httpwmsapi.get(`/v1/recebimento-produto/?${qry}`, { timeout: 1000 * 50 })
                .then(({ data }) => resolve(data)).catch(error => reject(error))
        })
    },

    putReceiving: (body) => {
        return new Promise((resolve, reject) => {
            httpwmsapi
                .put(`/v1/recebimento/`, body, { headers: { 'Content-Type': 'application/json' }, timeout: 1000 * 60 })
                .then((data) => resolve(data))
                .catch((error) => reject(error));
        });
    },

    setReceiving: (body) => {
        return new Promise((resolve, reject) => {
            httpwmsapi
                .post(`/v1/recebimento/`, body, { headers: { 'Content-Type': 'application/json' }, timeout: 1000 * 60 })
                .then((data) => resolve(data))
                .catch((error) => reject(error));
        });
    },

    setReceivingProduct: (body) => {
        return new Promise((resolve, reject) => {
            httpwmsapi
                .post(`/v1/recebimento-produto/`, body, { headers: { 'Content-Type': 'application/json' }, timeout: 1000 * 60 })
                .then((data) => resolve(data))
                .catch((error) => reject(error));
        });
    },

    deleteReceivingProduct: (codigo) => {
        return new Promise((resolve, reject) => {
            httpwmsapi
                .delete(`/v1/recebimento-produto/${codigo}`, {
                    timeout: 1000 * 15,
                })
                .then(({ data }) => resolve(data))
                .catch((error) => reject(error));
        });
    },

    cancelReceiving: (codigo) => {
        return new Promise((resolve, reject) => {
            httpwmsapi
                .delete(`/v1/recebimento/${codigo}`, {
                    timeout: 1000 * 15,
                })
                .then(({ data }) => resolve(data))
                .catch((error) => reject(error));
        });
    },

    finishReceiving: (codigo) => {
        return new Promise((resolve, reject) => {
            httpwmsapi
                .patch(`/v1/recebimento/${codigo}`, {
                    timeout: 1000 * 15,
                })
                .then(({ data }) => resolve(data))
                .catch((error) => reject(error));
        });
    },
}