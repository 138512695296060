import { http } from "src/services";

const STORAGE_KEY = "accessToken";

const UserApi = {
  /**
   * Faz um GET com id do modulo para obter as permissões do usuário
   * @param {Number} module_id
   * @returns
   */
  getPermission: async (module_id) => {
    return new Promise((resolve, reject) => {
      http
        .get(`/v2/core/users/permissions/modules/${module_id}`, {
          headers: { Authorization: `Bearer ${window.localStorage.getItem(STORAGE_KEY)}` },
        })
        .then((res) => resolve(res.data))
        .catch((err) => reject(err));
    });
  },
  /**
   * faz um GET pelo subbrupo dos parametros e carrega todos os parametros que o
   * usuário está habilitado ou cadastrado.
   * @param {String} subgroup
   * @returns
   */
  getParameters: async (params) => {
    const urlParams = new URLSearchParams();
    for (const key in params) {
      urlParams.append("params", params[key]);
    }
  
    return new Promise((resolve, reject) => {
      http
        .get(`/v2/core/users/permissions/parameters?${urlParams.toString()}`, {
          headers: { Authorization: `Bearer ${window.localStorage.getItem(STORAGE_KEY)}` },
        })
        .then((res) => resolve(res.data))
        .catch((err) => reject(err));
    });
  },
};

export default UserApi;